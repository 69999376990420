<mat-card  [fxFlex.lt-md]="200" fxLayout="column" fxLayoutAlign="center center" >
    <h4>{{'¿Desea realizar una consulta o una reclamación?' | translate}}</h4>
    <form [formGroup]="form">
        <mat-select placeholder="{{'consulta o reclamación' | translate}}" id="selector" formControlName="option">
            <mat-divider></mat-divider>
            <mat-option value="consulta" >{{'CONSULTA' | translate}}</mat-option>
            <mat-option value="reclamación" >{{'RECLAMACIÓN' | translate}}</mat-option>
        </mat-select>
            <br>
        <textarea id="texto" class="form-control" formControlName="texto"></textarea>
            <br>
        <button mat-stroked-button id="enviar" (click)="consultar()">{{'ENVIAR' | translate}}</button>
     </form>
</mat-card>