import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
import { GlobalService } from '../../../services/config/global/global.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { ValidacionesPerfilService } from '../../../services/config/validaciones/perfil/validaciones-perfil.service';
import { ContratosService } from 'src/app/services/contratos/contratos.service';
import { NgModel, FormControl } from '@angular/forms';

import { environment } from 'src/environments/environment';
import { CambiosDatosTitularComponent } from '../../dialogs/cambios-datos-titular/cambios-datos-titular.component';
import { CambiosDatosPagoContactoEnvioComponent } from '../../dialogs/cambios-datos-pago-contacto-envio/cambios-datos-pago-contacto-envio.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit {

  TipoCliente: string;
  CupsMultipunto: string;

  RazonSocialCliente: string;
  NombreCliente: string;
  Apellido1Cliente: string;
  Apellido2Cliente: string;
  CiudadCliente: string;
  CodigoPostalCliente: string;
  ProvinciaCliente: string;
  ColectivoCliente: string;
  DireccionCliente: string;
  IdCallejeroCliente: string;
  IdCiudadCliente: string;
  IdCliente: string;
  IdProvinciaCliente: string;
  IdentificadorClienteDNI: string;
  IdentificadorClienteEmail: string;
  IdentificadorRepresentante: string;
  IdColectivoRepresentante: string;
  NombreRepresentante: string;
  ColectivoRepresentante: string;
  ComentarioRepresentante: string;
  MetodosPago: any[] = [];
  DatosContacto: any[] = [];
  DatosEnvio: any[] = [];
  Email: any;

  IsAdministrador: any;

  PerfilTitularDocumentoIdentificativoDisabled: boolean;
  PerfilTitularEmailDisabled: boolean;
  PerfilTitularRazonSocialDisabled: boolean;
  PerfilTitularNombreDisabled: boolean;
  PerfilTitularApellido1Disabled: boolean;
  PerfilTitularApellido2Disabled: boolean;
  PerfilTitularCodigoPostalDisabled: boolean;
  PerfilTitularProvinciaDisabled: boolean;
  PerfilTitularCiudadDisabled: boolean;
  PerfilTitularDireccionDisabled: boolean;
  PerfilTitularDocumentoIdentificativoVisible: boolean;
  PerfilTitularEmailVisible: boolean;
  PerfilTitularRazonSocialVisible: boolean;
  PerfilTitularNombreVisible: boolean;
  PerfilTitularApellido1Visible: boolean;
  PerfilTitularApellido2Visible: boolean;
  PerfilTitularCodigoPostalVisible: boolean;
  PerfilTitularProvinciaVisible: boolean;
  PerfilTitularCiudadVisible: boolean;
  PerfilTitularDireccionVisible: boolean;
  PerfilRepresentanteColectivoDisabled: boolean;
  PerfilRepresentanteIdentificadorDisabled: boolean;
  PerfilRepresentanteNombreDisabled: boolean;
  PerfilRepresentanteComentarioDisabled: boolean;
  PerfilRepresentanteColectivoVisible: boolean;
  PerfilRepresentanteIdentificadorVisible: boolean;
  PerfilRepresentanteNombreVisible: boolean;
  PerfilRepresentanteComentarioVisible: boolean;

  PerfilMetodoPagoNombreDisabled: boolean;
  PerfilMetodoPagoColectivoDisabled: boolean;
  PerfilMetodoPagoIdentificadorDisabled: boolean;
  PerfilMetodoPagoTipoCobroDisabled: boolean;
  PerfilMetodoPagoIbanDisabled: boolean;
  PerfilMetodoPagoBancoDisabled: boolean;
  PerfilDatosContactoTipoContactoDisabled: boolean;
  PerfilDatosContactoValorDisabled: boolean;
  PerfilDatosContactoNombreContactoDisabled: boolean;
  PerfilDatosEnvioDatosDisabled: boolean;
  PerfilDatosEnvioPoblacionDisabled: boolean;
  PerfilDatosEnvioCodigoPostalDisabled: boolean;
  PerfilDatosEnvioDireccionDisabled: boolean;
  PerfilDatosEnvioNumeroDisabled: boolean;
  PerfilDatosEnvioAclaradorDisabled: boolean;

  MostrarBotonModificiacionDatosPerfil: boolean;
  dialogRef: any;

  TamanoListadoCupsCliente: any;
  DatosCupsMultipunto: any;
  Entorno: any;
  Nombre: any;
  RazonSocial: any;
  CodigoPostal: any;
  Provincia: any;
  Ciudad: any;
  Direccion: any;
  Distribuidora: any;
  Tarifa: any;
  TarifaGrupo: any;
  PerfilFacturacion: any;

  datosCupsMonopunto: any;
  cupsFiltered: any[] = [];
  tokenvalidado: any;
  OcultaTipoContacto1 = environment.OcultaTipoContacto1;
  OcultarContactosEleia = environment.OcultarContactosEleia;

  selectedCups = new FormControl();

  constructor(
    private router: Router,
    private _loginService: LoginService,
    private _globalService: GlobalService,
    private _funcionesService: FuncionesService,
    private _validacionesPerfilService: ValidacionesPerfilService,
    private _contratosService: ContratosService,
    public dialog: MatDialog
  ) {
    this.TipoCliente = localStorage.getItem('TipoCliente');
    this.cupsFiltered = JSON.parse(localStorage.getItem('cupsFiltered'));
  }

  ngOnInit() {
    this.MostrarBotonModificiacionDatosPerfil = environment.MostrarBotonModificiacionDatosPerfil;
    if (this._loginService.datosTitular === undefined) {

      this._loginService.getLogin(this._funcionesService.decodificarToken(JSON.parse(localStorage.getItem('u'))),
        this._funcionesService.decodificarToken(JSON.parse(localStorage.getItem('p'))))
        .toPromise()
        .then(async data => {

          this._loginService.datosTitular = this._funcionesService.decodificarToken(data);
          // console.log(this._loginService.datosTitular);
          this.loadData();

      });
    } else {
      this.loadData();
    }

    this.PerfilTitularDocumentoIdentificativoDisabled = this._validacionesPerfilService.PerfilTitularDocumentoIdentificativoDisabled;
    this.PerfilTitularEmailDisabled = this._validacionesPerfilService.PerfilTitularEmailDisabled;
    this.PerfilTitularRazonSocialDisabled = this._validacionesPerfilService.PerfilTitularRazonSocialDisabled;
    this.PerfilTitularNombreDisabled = this._validacionesPerfilService.PerfilTitularNombreDisabled;
    this.PerfilTitularApellido1Disabled = this._validacionesPerfilService.PerfilTitularApellido1Disabled;
    this.PerfilTitularApellido2Disabled = this._validacionesPerfilService.PerfilTitularApellido2Disabled;
    this.PerfilTitularCodigoPostalDisabled = this._validacionesPerfilService.PerfilTitularCodigoPostalDisabled;
    this.PerfilTitularProvinciaDisabled = this._validacionesPerfilService.PerfilTitularProvinciaDisabled;
    this.PerfilTitularCiudadDisabled = this._validacionesPerfilService.PerfilTitularCiudadDisabled;
    this.PerfilTitularDireccionDisabled = this._validacionesPerfilService.PerfilTitularDireccionDisabled;
    this.PerfilTitularDocumentoIdentificativoVisible = this._validacionesPerfilService.PerfilTitularDocumentoIdentificativoVisible;
    this.PerfilTitularEmailVisible = this._validacionesPerfilService.PerfilTitularEmailVisible;
    this.PerfilTitularRazonSocialVisible = this._validacionesPerfilService.PerfilTitularRazonSocialVisible;
    this.PerfilTitularNombreVisible = this._validacionesPerfilService.PerfilTitularNombreVisible;
    this.PerfilTitularApellido1Visible = this._validacionesPerfilService.PerfilTitularApellido1Visible;
    this.PerfilTitularApellido2Visible = this._validacionesPerfilService.PerfilTitularApellido2Visible;
    this.PerfilTitularCodigoPostalVisible = this._validacionesPerfilService.PerfilTitularCodigoPostalVisible;
    this.PerfilTitularProvinciaVisible = this._validacionesPerfilService.PerfilTitularProvinciaVisible;
    this.PerfilTitularCiudadVisible = this._validacionesPerfilService.PerfilTitularCiudadVisible;
    this.PerfilTitularDireccionVisible = this._validacionesPerfilService.PerfilTitularDireccionVisible;
    this.PerfilRepresentanteColectivoDisabled = this._validacionesPerfilService.PerfilRepresentanteColectivoDisabled;
    this.PerfilRepresentanteIdentificadorDisabled = this._validacionesPerfilService.PerfilRepresentanteIdentificadorDisabled;
    this.PerfilRepresentanteNombreDisabled = this._validacionesPerfilService.PerfilRepresentanteNombreDisabled;
    this.PerfilRepresentanteComentarioDisabled = this._validacionesPerfilService.PerfilRepresentanteComentarioDisabled;
    this.PerfilRepresentanteColectivoVisible = this._validacionesPerfilService.PerfilRepresentanteColectivoVisible;
    this.PerfilRepresentanteIdentificadorVisible = this._validacionesPerfilService.PerfilRepresentanteIdentificadorVisible;
    this.PerfilRepresentanteNombreVisible = this._validacionesPerfilService.PerfilRepresentanteNombreVisible;
    this.PerfilRepresentanteComentarioVisible = this._validacionesPerfilService.PerfilRepresentanteComentarioVisible;
    this.PerfilMetodoPagoNombreDisabled = this._validacionesPerfilService.PerfilMetodoPagoNombreDisabled;
    this.PerfilMetodoPagoColectivoDisabled = this._validacionesPerfilService.PerfilMetodoPagoColectivoDisabled;
    this.PerfilMetodoPagoIdentificadorDisabled = this._validacionesPerfilService.PerfilMetodoPagoIdentificadorDisabled;
    this.PerfilMetodoPagoTipoCobroDisabled = this._validacionesPerfilService.PerfilMetodoPagoTipoCobroDisabled;
    this.PerfilDatosContactoNombreContactoDisabled = this._validacionesPerfilService.PerfilDatosContactoNombreContactoDisabled;
    this.PerfilMetodoPagoIbanDisabled = this._validacionesPerfilService.PerfilMetodoPagoIbanDisabled;
    this.PerfilMetodoPagoBancoDisabled = this._validacionesPerfilService.PerfilMetodoPagoBancoDisabled;
    this.PerfilDatosContactoTipoContactoDisabled = this._validacionesPerfilService.PerfilDatosContactoTipoContactoDisabled;
    this.PerfilDatosContactoValorDisabled = this._validacionesPerfilService.PerfilDatosContactoValorDisabled;
    this.PerfilDatosEnvioDatosDisabled = this._validacionesPerfilService.PerfilDatosEnvioDatosDisabled;
    this.PerfilDatosEnvioPoblacionDisabled = this._validacionesPerfilService.PerfilDatosEnvioPoblacionDisabled;
    this.PerfilDatosEnvioCodigoPostalDisabled = this._validacionesPerfilService.PerfilDatosEnvioCodigoPostalDisabled;
    this.PerfilDatosEnvioDireccionDisabled = this._validacionesPerfilService.PerfilDatosEnvioDireccionDisabled;
    this.PerfilDatosEnvioNumeroDisabled = this._validacionesPerfilService.PerfilDatosEnvioNumeroDisabled;
    this.PerfilDatosEnvioAclaradorDisabled = this._validacionesPerfilService.PerfilDatosEnvioAclaradorDisabled;
    // this.TamanoListadoCupsCliente = this._loginService.datosTitular.CUPS.length;
    if (this.cupsFiltered && this.cupsFiltered[0] && this.cupsFiltered[0].IdCups != '') {
      this.BusquedaNuevoCups(this.cupsFiltered[0].IdCups);
      this.CupsMultipunto = this.cupsFiltered[0].IdCups;
    }
  }


  BusquedaNuevoCups(idCups) {

    this._contratosService.getDatosCups(idCups).subscribe(
      async datosCupsMultipunto => {

        this.DatosCupsMultipunto = this._funcionesService.decodificarToken(datosCupsMultipunto);

        // console.log(this.DatosCupsMultipunto);
        // console.log(Object.keys(this.DatosCupsMultipunto).length);

        if(this.DatosCupsMultipunto){
          // console.log(this.DatosCupsMultipunto);
          this.MetodosPago = this.DatosCupsMultipunto.MetodosPago[0];

          if (this.DatosCupsMultipunto.MetodosPago[0].TipoCobroPagador == 'DOMICILIADO 7'){
            this.DatosCupsMultipunto.MetodosPago[0].TipoCobroPagador = 'Domiciliado'
          }
          this.DatosContacto = [];
          this.DatosContacto = this.DatosCupsMultipunto.Contactos;
          // console.log(this.DatosContacto);
          this.DatosEnvio = this.DatosCupsMultipunto.Envios[0];
          // this.Email = this._loginService.datosTitular.EmailAdministrador;
        }
    });
  }

  loadData() {
    this.IsAdministrador = (localStorage.getItem('isAdminFincas') === 'true');
    if (this.IsAdministrador) {
      this.RazonSocialCliente = this._loginService.datosTitular.NombreAdministrador;
      this.CiudadCliente = this._loginService.datosTitular.CiudadAdministrador;
      this.CodigoPostalCliente = this._loginService.datosTitular.CodigoPostalAdministrador;
      this.DireccionCliente = this._loginService.datosTitular.DireccionAdministrador;
      this.Email = this._loginService.datosTitular.EmailAdministrador;
    } else {
      this.IdentificadorClienteDNI = this._loginService.datosTitular.IdentificadorClienteDNI;
      this.RazonSocialCliente = this._loginService.datosTitular.RazonSocialCliente;
      this.NombreCliente = this._loginService.datosTitular.NombreCliente;
      this.Apellido1Cliente = this._loginService.datosTitular.Apellido1Cliente;
      this.Apellido2Cliente = this._loginService.datosTitular.Apellido2Cliente;
      this.CiudadCliente = this._loginService.datosTitular.CiudadCliente;
      this.CodigoPostalCliente = this._loginService.datosTitular.CodigoPostalCliente;
      this.ProvinciaCliente = this._loginService.datosTitular.ProvinciaCliente;
      this.DireccionCliente = this._loginService.datosTitular.DireccionCliente;
      this.ColectivoCliente = this._loginService.datosTitular.ColectivoCliente;
      this.NombreRepresentante = this._loginService.datosTitular.NombreRepresentante;
      this.ColectivoRepresentante = this._loginService.datosTitular.ColectivoRepresentante;
      this.ComentarioRepresentante = this._loginService.datosTitular.ComentarioRepresentante;
      this.IdentificadorRepresentante = this._loginService.datosTitular.IdentificadorRepresentante;
      if (this._loginService.datosTitular.MetodosPago[0].TipoCobroPagador == 'DOMICILIADO 7'){
        this._loginService.datosTitular.MetodosPago[0].TipoCobroPagador = 'Domiciliado'
      }
      this.MetodosPago = this._loginService.datosTitular.MetodosPago[0];
      // console.log(this.MetodosPago);
      // this.DatosContacto = this._loginService.datosTitular.Contactos[0];
      this.DatosContacto = this._loginService.datosTitular.Contactos;
      this.DatosEnvio = this._loginService.datosTitular.Envios[0];
      this.Email = this._loginService.datosTitular.EmailAdministrador;
    }
  }

  openDialogCambiosDatosTitular() {

    let email = "";

    (this.DatosContacto.length > 0) ? email = this._funcionesService.buscarEmailContactos(this.DatosContacto) : null;

    this.dialogRef = this.dialog.open(CambiosDatosTitularComponent, {
      data:{
        'IsAdministrador': localStorage.getItem('isAdminFincas'),
        'IdCliente': localStorage.getItem('idCliente'),
        'Entorno': localStorage.getItem('entorno'),
        'IdentificadorClienteDNI': this.IdentificadorClienteDNI,
        'RazonSocialCliente': this.RazonSocialCliente,
        'Apellido1Cliente': this.Apellido1Cliente,
        'Apellido2Cliente': this.Apellido2Cliente,
        'CodigoPostalCliente': this.CodigoPostalCliente,
        'ProvinciaCliente': this.ProvinciaCliente,
        'CiudadCliente': this.CiudadCliente,
        'DireccionCliente': this.DireccionCliente,
        'IdentificadorRepresentante': this.IdentificadorRepresentante,
        'ColectivoRepresentante': this.ColectivoRepresentante,
        'NombreRepresentante': this.NombreRepresentante,
        'EmailContacto': email,
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }


  openDialogCambiosDatosPagoContactoEnvio() {

    let email = "";

    (this.DatosContacto.length > 0) ? email = this._funcionesService.buscarEmailContactos(this.DatosContacto) : null;

    this.dialogRef = this.dialog.open(CambiosDatosPagoContactoEnvioComponent, {
      data:{
        'IsAdministrador': localStorage.getItem('isAdminFincas'),
        'IdCliente': localStorage.getItem('idCliente'),
        'Entorno': localStorage.getItem('entorno'),
        'IdentificadorClienteDNI': this.IdentificadorClienteDNI,

        'MetodosPago': this.MetodosPago,
        'DatosContacto': this.DatosContacto,
        'DatosEnvio': this.DatosEnvio,
        'EmailContacto': email

      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }

}
