import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'tipoContacto'})
export class TipoContactoPipe implements PipeTransform {

  transform(value: any): any {
    let resp: string;
    switch (value) {
      case ('E'): resp = 'Email'; break;
      case ('F'): resp = 'Fax'; break;
      case ('M'): resp = 'Móvil'; break;
      case ('T'): resp = 'Teléfono'; break;
    }
    return resp;
  }

}
