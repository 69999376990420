<div mat-dialog-content fxLayout="column" class="dialog">

  <mat-card-title>
      {{'Descarga masiva de facturas' | translate}}
  </mat-card-title>

  <div *ngIf="!isLoading" fxLayout="column">
    <!-- Listado CUPS para consultar CCH -->
    <mat-checkbox (click)="selectAll(checkAll.checked)" class="myCheck" #checkAll>{{'Marcar / Desmarcar todos' | translate}}</mat-checkbox>
    <mat-form-field fxFlex="25" *ngIf="ListadoContratos.length > 0">
      <mat-select placeholder="CUPS" class="filter-select" [formControl]="selectedCups"
        (selectionChange)="BusquedaNuevoCups()" multiple>
        <input class="myInput" #myInput matInput focused="'true'" type="text" (keyup)="search($event.target.value)"
          autocomplete="off" placeholder="Buscar Cups">
        <mat-divider></mat-divider>
        <mat-option *ngFor="let cups of cupsFiltered" [value]="cups">{{cups}}</mat-option>
      </mat-select>
    </mat-form-field>


    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <div fxFlex="50">
        <!-- Calendario Fecha Inicio -->
        <mat-form-field>
          <input matInput [matDatepicker]="CalFecInicio" [min]="FecInicioAux" [max]="FecFin" placeholder="{{'Fecha Inicio' | translate}}"
            [value]='FecInicio' [(ngModel)]="FecInicio" (dateChange)='CalculoSeleccionFechasInicio(FecInicio)' disabled>
          <mat-datepicker-toggle matSuffix [for]="CalFecInicio"></mat-datepicker-toggle>
          <mat-datepicker #CalFecInicio disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <!-- Calendario Fecha Fin -->
        <mat-form-field>
          <input matInput [matDatepicker]="CalFecFin" [min]="FecInicio" [max]="FecFinAux" placeholder="{{'Fecha Fin' | translate}}"
            [value]='FecFin' [(ngModel)]="FecFin" (dateChange)='CalculoSeleccionFechasFin(FecFin)' disabled>
          <mat-datepicker-toggle matSuffix [for]="CalFecFin"></mat-datepicker-toggle>
          <mat-datepicker #CalFecFin disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>  



    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <div fxFlex="50">
        <!-- CIF -->
        <mat-form-field *ngIf="MostrarDescargaMasivaFacturasFiltroCif">
          <input matInput [formControl]="cif" class="myInput" #myInput matInput type="text" placeholder="{{'CIF' | translate}}">
        </mat-form-field>
      </div>
      <div fxFlex="50">
        <!-- Importe -->
        <mat-form-field  *ngIf="MostrarDescargaMasivaFacturasFiltroImporte">
          <input matInput [formControl]="importe" class="myInput" #myInput matInput type="number" step="0.01" placeholder="{{'Importe' | translate}}">
        </mat-form-field>
      </div>
    </div>

    <!-- Tarifa -->
    <mat-form-field *ngIf="MostrarDescargaMasivaFacturasFiltroTarifa">
      <mat-select placeholder="Tarifas" class="filter-select" [formControl]="selectedtarifa">
        <mat-option *ngFor="let tarifa of arrayTarifas" [value]="tarifa">{{tarifa}}</mat-option>
      </mat-select>
    </mat-form-field>

    
    <br>
    <button mat-stroked-button (click)="descargarFacturasLote('zip')" [disabled]="arrayCupsSeleccionados.length < 1 && !cif.value" id="enviar" ><i class="fas fa-file-archive"></i> {{'DESCARGAR ZIP' | translate}}</button><br/>
    <button *ngIf="MostrarDescargaMasivaFacturasBotonExcel" mat-stroked-button (click)="descargarFacturasLote('excel')" [disabled]="arrayCupsSeleccionados.length < 1" id="enviar" ><i class="fas fa-file-excel"></i> {{'DESCARGAR EXCEL' | translate}}</button>
    <!-- <button mat-stroked-button (click)="descargarFacturasLote()" [disabled]="arrayCupsSeleccionados.length < 1" id="enviar" [mat-dialog-close]>{{'DESCARGAR FACTURAS' | translate}}</button> -->
  </div>
    <!-- spinner -->
  <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
