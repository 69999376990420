import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../../services/config/global/global.service';

@Injectable({
  providedIn: 'root'
})
export class CondicionesService {

  token: string;
  urlApi: string;
  bodyApiToken: any;

  constructor(
    private http: HttpClient,
    private _globalService: GlobalService) {
    // Direccion url API
    this.urlApi = this._globalService.getUrlApi();
    this.token = localStorage.getItem('token');
  }

  checkCondicionesUsoCliente(body): Observable<any> {

    const options = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
    // return this.http.post<any>('http://80.35.34.4:8383/ov/eleia/libs/php/ctrl_aceptacion_condiciones.php', body, options);
    return this.http.post<any>('libs/php/ctrl_aceptacion_condiciones.php', body, options);
  }

}