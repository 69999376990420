<div id="loginWrapper" class="mt-30">
  <div fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
    <div fxFlex="20" [fxFlex.md]="40" [fxFlex.sm]="50" [fxFlex.xs]="100" [fxFlex.xl]="20" [fxFlex.lg]="20">
      <div class="msg-login">
      <div class="messageLogin alert" *ngIf="messageLogin">
        <span class="closebtn" onclick="this.parentElement.style.visibility='hidden';">&times;</span>
          <p><b>{{'¡Aviso Importante!' | translate}}</b></p>
          <p>{{'Hemos modificado nuestra oficina virtual para mejorar tu experiencia de uso. El usuario que identificará tu acceso será el' | translate}}
            {{ UserAccess }}
            {{'asociado a tu contrato con' | translate}}  DISA Energía Eléctrica.</p>
          <p>{{'Además deberás renovar tu contraseña debido a cuestiones de seguridad.' | translate}}</p>
      </div>
      <div class="messageLogin alert" *ngIf="messageRecuperarPass">
        <span class="closebtn" onclick="this.parentElement.style.visibility='hidden';">&times;</span>

        <p><b>{{'¡Aviso Importante!' | translate }}</b></p>
        <p>{{'Por motivos de seguridad, para acceder por primera vez debe generar una nueva clave de acceso.' | translate }}</p>
        <p>{{'Pulse en el enlace ¿Olvido su contraseña? e introduzca el CIF o DNI del titular del suministro con la letra en mayúscula para recibir en su email la nueva clave.' | translate }}</p>

        </div>
      <app-translation *ngIf="idiomas"></app-translation>
      <mat-card>
        <img src="assets/img/logo.svg" id="logoEmpresa" alt="Avatar">
        <mat-card-header fxLayoutAlign="center center">
            <mat-card-title>{{'INICIO DE SESIÓN' | translate }}</mat-card-title>
          <mat-card-subtitle>{{'Introduce tus credenciales' | translate }}.</mat-card-subtitle>
        </mat-card-header>
        <form #f="ngForm" novalidate (ngSubmit)="validaLogin(f)" id="formulario" class="p-20">
          <mat-form-field fxFlexFill class="pb-20">
            <input class="dni" type="text" matInput name="usuario" [(ngModel)]="usuario" placeholder='{{ UserAccess }}' required>
          </mat-form-field>
          <mat-form-field fxFlexFill class="pb-20">
            <input type="password" matInput name="contrasena" [(ngModel)]="contrasena" placeholder="{{ 'Contraseña' | translate }}" required>
          </mat-form-field>
           <div *ngIf="TextoLegal!=''">
              <p class="textoLegal">{{TextoLegal}}</p>
              <a href="{{urlPoliticaPrivacidad}}" target="_blank">Política de privacidad</a>
            </div>
         <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
            <!-- <span fxFlex>{{ mensajeError }}</span> -->
            <a mat-button (click)='primerLoginf(true);' [routerLink]="['/recordarcontrasena']" *ngIf="primerLogin">{{ "Primer acceso" | translate }}</a>
            <a mat-button (click)='primerLoginf(false);'[routerLink]="['/recordarcontrasena']">{{'¿Olvidó su contraseña?' | translate}}</a>
            <button *ngIf="botonAccederVisibleLogin" type="submit" mat-raised-button color="primary" [disabled]="f.invalid">{{'Acceder' | translate}}</button>
            <!-- <button (click)="window.localStorage.clear()" *ngIf="botonAccederVisibleLogin" type="submit" mat-raised-button color="primary" [disabled]="f.invalid">{{'Acceder' | translate}}</button> -->
            <mat-progress-spinner *ngIf="loadingSpinnerLogin" [diameter]="25" style="margin-left: 20px; margin-top: 20px;"
              color="primary" mode="indeterminate"></mat-progress-spinner>
          </div>
        </form>
      </mat-card>
    </div>
    <div></div>
  </div>
</div>

<app-footer></app-footer>
