import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import {Injectable} from '@angular/core';

@Injectable()
export class PaginatorI18n extends MatPaginatorIntl{

    constructor(private readonly translate: TranslateService) {
        super();

        this.translate.onLangChange.subscribe((e: Event) => {
            this.getAndInitTranslations();
          });
      
          this.getAndInitTranslations();
    }

    getPaginatorIntl(): MatPaginatorIntl {
        const paginatorIntl = new MatPaginatorIntl();
        paginatorIntl.itemsPerPageLabel = this.translate.instant('Filas por página:');
        paginatorIntl.nextPageLabel = this.translate.instant('Página siguiente');
        paginatorIntl.previousPageLabel = this.translate.instant('Página anterior');
        paginatorIntl.firstPageLabel = this.translate.instant('Primera página');
        paginatorIntl.lastPageLabel = this.translate.instant('Última pagina');
        paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
        return paginatorIntl;
    }

    getAndInitTranslations() {
        this.translate.get(['Filas por página:', 'Página siguiente', 'Página anterior', 'OF_LABEL']).subscribe(translation => {
          this.itemsPerPageLabel = translation['Filas por página:'];
          this.nextPageLabel = translation['Página siguiente'];
          this.previousPageLabel = translation['Página anterior'];
          this.changes.next();
        });
      }

    /* Como estaba  antes
    private getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0 || pageSize === 0) {
            return this.translate.instant('RANGE_PAGE_LABEL_1', { length });
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return this.translate.instant('RANGE_PAGE_LABEL_2', { startIndex: startIndex + 1, endIndex, length });
    }*/


    getRangeLabel = (page: number, pageSize: number, length: number) =>  {
        if (length === 0 || pageSize === 0) { return `0 ` + this.translate.instant('de') + ` ${length}`; }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;

        return `${startIndex + 1} ` + this.translate.instant('de') + ` ${length}`;
    }
}
