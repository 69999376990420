<app-header></app-header>

<div class="wrapper">
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayoutGap="20px">
      <mat-card class="of p-0">
        <table mat-table [dataSource]="dataSource" matSort matSortActive="FechaFactura" matSortDirection="desc">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                [disabled]="OcultarFacturasConecta2DeAntesDel31x11x19 && getMilisegundosDeFecha(row.FechaFactura) < fechaLimite.getTime()" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>
          <!-- Entorno -->
          <ng-container matColumnDef="Entorno">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Entorno' | translate }}</span>
              <input class="filter-input" matInput [formControl]="EntornoFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="far fa-lightbulb fa-lg" *ngIf="element.Entorno === 'Luz'" aria-hidden="true"
                matTooltip="Luz"></span>
              <span class="far fa-fire fa-lg" *ngIf="element.Entorno === 'Gas'" aria-hidden="true"
                matTooltip="Gas"></span>
            </td>
          </ng-container>
          <!-- Factura -->
          <ng-container matColumnDef="Factura">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Factura' | translate }}</span>
              <input class="form-field" matInput [formControl]="NumeroFacturaFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="!element.Serie && !element.NumeroFactura; else elseNombreFacturaBlock">
                <i class="text-muted">-</i>
              </ng-container>
              <ng-template #elseNombreFacturaBlock>
                <ng-container *ngIf="SimplificacionSerieFactura && isNumber(element.Serie)">{{PrefijoNumeracionFacturas}}{{element.Serie}}{{element.NumeroFactura}}</ng-container>
                <ng-container *ngIf="!(SimplificacionSerieFactura && isNumber(element.Serie))">{{PrefijoNumeracionFacturas}} {{element.Serie}} {{element.NumeroFactura}}</ng-container>
              </ng-template>
            </td>
          </ng-container>
          <!-- Fecha Factura -->
          <ng-container matColumnDef="FechaFactura">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Fecha Factura' | translate }}</span>
              <input class="filter-input" matInput [formControl]="FechaFacturaFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">{{element.FechaFactura | date: 'dd/MM/yyyy'}} </td>
          </ng-container>
          <!-- Periodo de facturación -->
          <ng-container matColumnDef="PeriodoFacturacion">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Periodo de facturación' | translate }}</span>
              <input class="filter-input" matInput [formControl]="FechaDesdeFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">{{'De ' | translate}} {{element.FechaDesde | date: 'dd/MM/yyyy'}}  {{' a ' | translate}}{{element.FechaHasta | date: 'dd/MM/yyyy'}}</td>
          </ng-container>
          <!-- Fecha Vencimiento -->
          <ng-container matColumnDef="FechaVencimiento">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Fecha Vencimiento' | translate }}</span>
              <input class="filter-input" matInput [formControl]="FechaVencimientoFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">{{element.FechaVencimiento | date: 'dd/MM/yyyy'}}</td>
          </ng-container>
          <!-- Fecha Limite pago -->
          <ng-container matColumnDef="FechaLimitePago">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Fecha Limite Pago' | translate }}</span>
              <input class="filter-input" matInput [formControl]="FechaLimitePagoFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">{{element.FechaLimitePago | date: 'dd/MM/yyyy'}}</td>
          </ng-container>
          <!-- Importe total -->
          <ng-container matColumnDef="ImporteTotal">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Importe' | translate}}</span>
              <input class="filter-input" matInput [formControl]="ImporteTotalFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">{{element.ImporteTotal | currency:'EUR':'symbol':'':'es'}}</td>
          </ng-container>
          <!-- Estado -->
          <ng-container matColumnDef="Estado">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Estado' | translate}}</span>
              <!-- <input class="filter-input" matInput [formControl]="EstadoFilter" placeholder="Filtrar" /> -->
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.Estado; else elseEstadoBlock">
                <button mat-button disabled>
                  <span class="fal fa-check-circle fa-lg" matTooltip="Terminado"></span>
                </button>
              </ng-container>
              <ng-template #elseEstadoBlock>
                <button mat-button disabled>
                  <span class="fal fa-exclamation-circle fa-lg" matTooltip="Pendiente"></span>
                </button>
              </ng-template>
            </td>
          </ng-container>
          <!-- Descargar -->
          <ng-container matColumnDef="Descargar">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Descargar' | translate}}</span>
              <!-- <input class="filter-input" matInput [formControl]="DescargarFilter" placeholder="Filtrar" /> -->
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.IdContratoDocumento; else elseDescargarBlock">
                <button mat-button (click)="descargaFactura(element)" *ngIf="!OcultarFacturasConecta2DeAntesDel31x11x19 || (OcultarFacturasConecta2DeAntesDel31x11x19 && getMilisegundosDeFecha(element.FechaFactura) > fechaLimite.getTime())">
                  <span class="fal fa-file-download fa-lg" matTooltip="Descargar factura"></span>
                </button>
                <button mat-button disabled *ngIf="OcultarFacturasConecta2DeAntesDel31x11x19 && getMilisegundosDeFecha(element.FechaFactura) < fechaLimite.getTime()">
                  <span class="fal fa-file fa-lg" matTooltip="Factura no disponible, contacta con nosotros"></span>
                </button>
              </ng-container>
              <ng-template #elseDescargarBlock>
                <button mat-button disabled>
                  <span class="fal fa-file fa-lg" matTooltip="Factura no disponible, contacta con nosotros"></span>
                </button>
              </ng-template>
            </td>
          </ng-container>
          <!-- Pagar -->
          <ng-container matColumnDef="Pagar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Pagar' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <button *ngIf="!element.Estado" mat-button (click)="redsysdialog(element)">
                <span class="fal fa-credit-card fa-lg" matTooltip="Pagar ahora"></span>
              </button>
              <button *ngIf="element.Estado" mat-button (click)="expression" disabled>
                <span class="fal fa-credit-card-blank fa-lg" matTooltip="Pago completado"></span>
              </button>
            </td>
          </ng-container>

          <!-- Precios excel -->
          <ng-container matColumnDef="Precios" *ngIf="MostrarFacturaExcel">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Precios' | translate}}</span>
              <!-- <input class="filter-input" matInput [formControl]="DescargarFilter" placeholder="Filtrar" /> -->
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.IdDocumentoDescargable; else elseDescargarPreciosBlock">
                <button mat-button (click)="descargaPrecios(element)">
                  <span class="fal fa-file-spreadsheet fa-lg" matTooltip="Descargar precios"></span>
                </button>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
          <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>

        <div fxLayoutAlign="center"  *ngIf="textoTablaFacturasVacia">
          <span> {{'No hay datos disponibles.' | translate}}</span>
        </div>

        <button *ngIf="btnDescargaFacturasMasivaVisible" matTooltip="Descargar facturas" mat-button color="primary"
          class="mt-20 primary" [ngClass]="primary" (click)="descargarSeleccionados();">
          <i class="far fa-file-archive fa-2x"></i> {{'Comprimido' | translate}}
        </button>
        <mat-progress-spinner *ngIf="loadingSpinnerDescarga" [diameter]="25"
          style="margin-left: 20px; margin-top: 20px;" color="primary" mode="indeterminate">
        </mat-progress-spinner>
        <button *ngIf="btnDescargaFacturasExcelVisible" mat-button color="primary"
          matTooltip="Descargar facturas en excel" (click)="descargarSeleccionadosExcel();"><i
            class="fas fa-file-excel fa-2x"></i> Excel</button>
        <button *ngIf="MostrarBotonFacturasImpagadas" mat-button color="primary" [disabled] = "!facturas || facturas.length < 1"
          matTooltip="Mostrar sólo facturas impagadas" (click)="mostrarSoloFacturasImpagadas()" ><i
            class="fas fa-file-exclamation fa-2x"></i>
            <span *ngIf="facturasImpagadas"> {{'Mostrar todas' | translate}}</span>
            <span *ngIf="!facturasImpagadas"> {{'Mostrar impagadas' | translate}}</span>
        </button>

        <mat-progress-spinner *ngIf="loadingSpinnerDescargaExcel" [diameter]="25"
          style="margin-left: 20px; margin-top: 20px;" color="primary" mode="indeterminate">
        </mat-progress-spinner>
        <mat-paginator [pageSizeOptions]="[12, 24, 36]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>
</div>

<app-footer></app-footer>
