import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidacionesPerfilService {
  PerfilTitularDocumentoIdentificativoDisabled = true;
  PerfilTitularEmailDisabled = true;
  PerfilTitularRazonSocialDisabled = true;
  PerfilTitularNombreDisabled = true;
  PerfilTitularApellido1Disabled = true;
  PerfilTitularApellido2Disabled = true;
  PerfilTitularCodigoPostalDisabled = true;
  PerfilTitularProvinciaDisabled = true;
  PerfilTitularCiudadDisabled = true;
  PerfilTitularDireccionDisabled = true;
  PerfilTitularDocumentoIdentificativoVisible = true;
  PerfilTitularEmailVisible = true;
  PerfilTitularRazonSocialVisible = true;
  PerfilTitularNombreVisible = true;
  PerfilTitularApellido1Visible = true;
  PerfilTitularApellido2Visible = true;
  PerfilTitularCodigoPostalVisible = true;
  PerfilTitularProvinciaVisible = true;
  PerfilTitularCiudadVisible = true;
  PerfilTitularDireccionVisible = true;

  PerfilRepresentanteColectivoDisabled = true;
  PerfilRepresentanteIdentificadorDisabled = true;
  PerfilRepresentanteNombreDisabled = true;
  PerfilRepresentanteComentarioDisabled = true;
  PerfilRepresentanteColectivoVisible = true;
  PerfilRepresentanteIdentificadorVisible = true;
  PerfilRepresentanteNombreVisible = true;
  PerfilRepresentanteComentarioVisible = true;

  PerfilMetodoPagoNombreDisabled = true;
  PerfilMetodoPagoColectivoDisabled = true;
  PerfilMetodoPagoIdentificadorDisabled = true;
  PerfilMetodoPagoTipoCobroDisabled = true;
  PerfilMetodoPagoIbanDisabled = true;
  PerfilMetodoPagoBancoDisabled = true;

  PerfilDatosContactoNombreContactoDisabled = true;
  PerfilDatosContactoTipoContactoDisabled = true;
  PerfilDatosContactoValorDisabled = true;

  PerfilDatosEnvioDatosDisabled = true;
  PerfilDatosEnvioPoblacionDisabled = true;
  PerfilDatosEnvioCodigoPostalDisabled = true;
  PerfilDatosEnvioDireccionDisabled = true;
  PerfilDatosEnvioNumeroDisabled = true;
  PerfilDatosEnvioAclaradorDisabled = true;

  PerfilCupsCodigoCupsDisabled = true;
  PerfilCupsDistribuidoraDisabled = true;
  PerfilCupsCodigoCnaeDisabled = true;
  PerfilCupsRazonSocialDisabled = true;
  PerfilCupsNombreDisabled = true;
  PerfilCupsApellidosDisabled = true;
  PerfilCupsCodigoPostalDisabled = true;
  PerfilCupsCiudadDisabled = true;
  PerfilCupsDireccionDisabled = true;
  PerfilCupsNumeroDisabled = true;
  PerfilCupsAclaradorDisabled = true;
  PerfilCupsObservacionesDisabled = true;

  PerfilIdentidadIdentidadClienteDisabled = true;
  PerfilIdentidadNombreClienteDisabled = true;
  PerfilIdentidadCodigoPostalClienteDisabled = true;
  PerfilIdentidadProvinciaClienteDisabled = true;
  PerfilIdentidadCiudadClienteDisabled = true;
  PerfilIdentidadDireccionClienteDisabled = true;

  PerfilIdentidadNombrePagador = true;
  PerfilIdentidadColectivoPagador = true;
  PerfilIdentidadIdentificadorPagador = true;
  PerfilIdentidadTipoCobroPagador = true;
  PerfilIdentidadIBANPagador = true;
  PerfilIdentidadBancoPagador = true;
  PerfilIdentidadNombreContacto = true;
  PerfilIdentidadTipoContacto = true;
  PerfilIdentidadValorContacto = true;
  PerfilIdentidadNombreEnvio = true;
  PerfilIdentidadPoblacionEnvio = true;
  PerfilIdentidadCodigoPostalEnvio = true;
  PerfilIdentidadDireccionEnvio = true;
  PerfilIdentidadNumeroEnvio = true;
  PerfilIdentidadAclaradorEnvio = true;

  PerfilContratoNombreComercializadora = true;
  PerfilContratoNombreDistribuidora = true;
  PerfilContratoCodigoContrato = true;
  PerfilContratoCodigoCups = true;
  PerfilContratoContratoSituacion = true;
  PerfilContratoIdentidad = true;
  PerfilContratoFechaAlta = true;
  PerfilContratoFechaBaja = true;
  PerfilContratoFechaContrato = true;
  PerfilContratoFechaVto = true;
  PerfilContratoTextoCiudad = true;
  PerfilContratoDireccion = true;

  PerfilContratoTarifa = true;
  PerfilContratoTarifaGrupo = true;
  PerfilContratoPerfilFacturacion = true;



  constructor() {}
}
