  <h2 mat-dialog-title>{{ tituloModal }}</h2>

  <mat-dialog-content>
    <span class="mat-typography">
      {{ mensajeModal }}
    </span>
    <mat-divider></mat-divider>
    <button mat-button class="mat-primary text-sm boton" (click)="busquedaCchPorFechas()"> {{'Aceptar' | translate}}
    </button>
    <button mat-button class="mat-primary text-sm boton" (click)="rechazarBusqueda()"> {{'Rechazar' | translate}}
    </button>
   </mat-dialog-content>