<div mat-dialog-content fxLayout="column" class="dialog">

  <mat-card-title>
      {{'Solicitud de cambio de datos titular' | translate}}
  </mat-card-title>

  <div *ngIf="!isLoading" fxLayout="column">

      <!-- Documento / Email -->

      <!-- Razón social -->
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="data.RazonSocial!=''">
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Documento identificativo' | translate}}" value="{{IdentificadorClienteDNI}}" [(ngModel)]="Identidad">
          <span class="fal fa-file-alt" matSuffix></span>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Razon Social' | translate}}" value="{{RazonSocialCliente}}" [(ngModel)]="RazonSocialCliente">
          <span class="fal fa-building" matSuffix></span>
        </mat-form-field>
      </div>
      <!-- Nombre / Primer apellido / Segundo apellido -->
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="data.RazonSocial==''">
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Nombre' | translate}}" value="{{NombreCliente}}" [(ngModel)]="NombreCliente">
          <span class="fal fa-user-alt" matSuffix></span>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Primer Apellido' | translate}}" value="{{Apellido1Cliente}}" [(ngModel)]="Apellido1Cliente">
          <span class="fal fa-user-alt" matSuffix></span>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Segundo Apellido' | translate}}" value="{{Apellido2Cliente}}" [(ngModel)]="Apellido2Cliente">
          <span class="fal fa-user-alt" matSuffix></span>
        </mat-form-field>
      </div>
      <!-- Código Postal / Provincia / Ciudad -->
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Código Postal' | translate}}" value="{{CodigoPostalCliente}}"[(ngModel)]="CodigoPostalCliente">
          <span class="fal fa-map-pin" matSuffix></span>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Provincia' | translate}}" value="{{ProvinciaCliente}}" [(ngModel)]="ProvinciaCliente">
          <span class="fal fa-map-marker-alt" matSuffix></span>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Ciudad' | translate}}" value="{{CiudadCliente}}" [(ngModel)]="CiudadCliente">
          <span class="fal fa-city" matSuffix></span>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Dirección' | translate}}" value="{{DireccionCliente}}" [(ngModel)]="DireccionCliente">
          <span class="fal fa-address-book" matSuffix></span>
        </mat-form-field>
      </div>
      <!-- REPRESENTANTE -->
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="IdentificadorRepresentante">
        <h3>{{'DATOS DE REPRESENTANTE' | translate}}</h3>
      </div>
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="IdentificadorRepresentante">
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Identificador' | translate}}" value="{{IdentificadorRepresentante}}" [(ngModel)]="IdentificadorRepresentante">
          <span class="fal fa-file-alt" matSuffix></span>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Colectivo' | translate}}" value="{{ColectivoRepresentante}}" [(ngModel)]="ColectivoRepresentante">
          <span class="fal fa-user-alt" matSuffix></span>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Nombre' | translate}}" value="{{NombreRepresentante}}" [(ngModel)]="NombreRepresentante">
          <span class="fal fa-user-alt" matSuffix></span>
        </mat-form-field>
      </div>

      <!-- CAPTCHA-->
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Captcha' | translate}}: {{varOne}} + {{varTwo}}" value="" [(ngModel)]="captcha">
          <span class="fal fa-calculator-alt" matSuffix></span>
        </mat-form-field>
      </div>

      <mat-card-actions fxLayout="row" fxLayoutAlign="center end" fxLayoutGap="20px" class="m-20">
        <button mat-button color="primary" (click)="onEnviarCambios()" 
        [disabled]="DireccionCliente=='' || CiudadCliente=='' || ProvinciaCliente=='' || CodigoPostalCliente=='' || Identidad=='' || 
        (data.RazonSocialCliente=='' && (Apellido2Cliente=='' || Apellido1Cliente=='' || NombreCliente=='')) ||
        (data.RazonSocialCliente!='' && RazonSocialCliente=='') ||
        (data.IdentificadorRepresentante!='' && IdentificadorRepresentante=='') ||
        (data.IdentificadorRepresentante!='' && ColectivoRepresentante=='') ||
        (data.IdentificadorRepresentante!='' && NombreRepresentante=='') ||
        resultSumOneTwo!=captcha"
        [mat-dialog-close]><i class="far fa-mail-bulk"></i> {{'Enviar solicitud de cambios' | translate}}</button>
      </mat-card-actions>
  
  
  
  
  </div>
    <!-- spinner -->
  <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
