import { Injectable } from '@angular/core';
import { GlobalService } from './../config/global/global.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class FuncionesService {
  iv: any;
  data: string;
  key: string;
  _enid: string;

  constructor(private _globalService: GlobalService) {}

  decodificarToken(token) {
    try {
      this.key = CryptoJS.enc.Utf8.parse(this._globalService.getCryptoKey());
      this.iv = CryptoJS.enc.Utf8.parse(this._globalService.getCryptoIV());
      const decrypted = CryptoJS.AES.decrypt(token.TextoCifrado, this.key, {
        keySize: 256,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decrypted);
    } catch (error) {
      console.error('Error al decodificar Token: ' + error);
    }
  }

  codificarDatos(data: string) {
    try {
      this.key = CryptoJS.enc.Utf8.parse(this._globalService.getCryptoKey());
      this.iv = CryptoJS.enc.Utf8.parse(this._globalService.getCryptoIV());
      const json = JSON.stringify(data);
      const encrypted = CryptoJS.AES.encrypt(json, this.key, {
        keySize: 256,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return JSON.stringify({'TextoCifrado': CryptoJS.enc.Base64.stringify(encrypted.ciphertext)});
    } catch (error) {
      console.error('Error al codificar: ' + error);
    }
    // return JSON.parse(this._enid);
  }

  buscarEmailContactos(contactos) {

    let email = "";

    contactos.forEach(contacto => {
      (contacto.TipoContacto == "E") ? email = contacto.ValorContacto : null;
    });

    return email;
  }

}
