import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../../services/config/global/global.service';
import { LoginService } from '../../services/login/login.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContratosService {
  urlApi: string;
  token: string;
  bodyApiToken: any;
  listadoContratos: any; // TODO: Cambiar por Contrato[]
  ultimosContratos: any; // TODO
  companyName: string;
  agrupacontratoscups = environment.agrupacontratoscups;

  constructor(
    private http: HttpClient,
    private _globalService: GlobalService
  ) {
    this.urlApi = this._globalService.getUrlApi();
    if(localStorage.getItem("companyName")){
      this.companyName = localStorage.getItem("companyName");
    }else{
      this.companyName=environment.companyName;
    }
  }

  getListadoContratos(idCliente, IdCups?): Observable<any> {
    this.refrescarApi();
    let body = {};
    if (localStorage.getItem('isAdminFincas') === 'true') {
      body = { IdCliente: idCliente, IsAdministrador: true};
    } else {
      body = { IdCliente: idCliente, IsAdministrador: false};
    }
    if(IdCups != undefined && this.agrupacontratoscups){
      body['IdCUps'] = IdCups;
      return this.http.post<any>(this.urlApi + 'Contrato/GetContratosLista', body, this._globalService.getHttpHeaders());
    }

    if(this.agrupacontratoscups){
      return this.http.post<any>(this.urlApi + 'Contrato/GetContratosAgrupadosLista', body, this._globalService.getHttpHeaders());
    }else{
      return this.http.post<any>(this.urlApi + 'Contrato/GetContratosLista', body, this._globalService.getHttpHeaders());
    }

  }
  
  getListadoContratosElekluz(idCliente, IdCups?): Observable<any> {
    this.refrescarApi();
    let body = {};
    if (localStorage.getItem('isAdminFincas') === 'true') {
      body = { IdCliente: idCliente, IsAdministrador: true};
    } else {
      body = { IdCliente: idCliente, IsAdministrador: false};
    }
    if(IdCups != undefined && this.agrupacontratoscups){
      body['IdCUps'] = IdCups;
      return this.http.post<any>(this.urlApi + 'Contrato/GetContratosListaElekluz', body, this._globalService.getHttpHeaders());
    }

    if(this.agrupacontratoscups){
      return this.http.post<any>(this.urlApi + 'Contrato/GetContratosAgrupadosLista', body, this._globalService.getHttpHeaders());
    }else{
      return this.http.post<any>(this.urlApi + 'Contrato/GetContratosListaElekluz', body, this._globalService.getHttpHeaders());
    }

  }



  getDatosCups(idCups): Observable<any> {
    this.refrescarApi();
    const body = { IdCups: idCups, IdCliente: localStorage.getItem('idCliente'), isAdministrador: localStorage.getItem('isAdminFincas') };
    return this.http.post<any>(this.urlApi + 'CUPS/GetDatosCUPSComprobarCliente', body, this._globalService.getHttpHeaders());
  }

  getDatosContrato(idContrato): Observable<any> {
    this.refrescarApi();
    const body = { IdContrato: idContrato };
    let apiCallCompany = 'Contrato/GetContratoComprobarCliente'; // default

    // Depende de la empresa se modifica la llamada
    switch (this.companyName) {
      case 'Eleia':
        apiCallCompany = 'Contrato/GetContrato_Eleia';
        break;
    }
    return this.http.post<any>(this.urlApi + apiCallCompany, body, this._globalService.getHttpHeaders());
  }

  getDatosCliente(idcliente): Observable<any> {
    this.refrescarApi();
    const body = { IdCliente: idcliente };
    return this.http.post<any>(this.urlApi + 'Cliente/GetDatosClienteComprobarCliente', body, this._globalService.getHttpHeaders());
  }

  getUltimosContratos(idAdministrador, fechaInicio, fechaFin): Observable<any> {
    this.refrescarApi();
    const body = { IdAdministrador: idAdministrador, FechaInicio: fechaInicio, FechaFin: fechaFin };
    return this.http.post<any>(this.urlApi + 'Resumen/UltimosContratos', body, this._globalService.getHttpHeaders());
  }

  getEstados(id): Observable<any> {
    this.refrescarApi();
    const body = { IdAdministrador: id };
    return this.http.post<any>(this.urlApi + 'Resumen/GetEstados', body, this._globalService.getHttpHeaders());
  }

  getAdminInfo(id, isAdministrador): Observable<any> {
    this.refrescarApi();
    const body = { Id: id, IsAdministrador: isAdministrador};
    return this.http.post<any>(this.urlApi + 'Resumen/GetInfoAdmin', body, this._globalService.getHttpHeaders());
  }

  refrescarApi(){
    if(this.urlApi != this._globalService.getUrlApi()){
      this.urlApi= this._globalService.getUrlApi();
    }
  }


}
