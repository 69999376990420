<footer fxLayout.xs="column" fxLayoutAlign="center" >
  <span class="mat-caption mr-20 " *ngIf="name!=''"><span class="far fa-building"></span>{{ name }}</span>
  <span class="mat-caption mr-20" *ngIf="address!=''"><span class="far fa-map-marked-alt"></span>{{ address }}</span>
  <span class="mat-caption mr-20" *ngIf="email!=''"><span class="far fa-envelope"></span>{{ email }}</span>
  <span class="mat-caption mr-20" *ngIf="phone!=''"><span class="far fa-phone"></span>{{ phone }}</span>
  <span class="mat-caption mr-20" *ngIf="urlAvisoLegal!=''"><a href="{{ urlAvisoLegal }}" target="_blank" title="Consulte nuestro Aviso Legal, Política de Privacidad y Protección de Datos">{{'Aviso legal' | translate}}</a></span>
  <span class="mat-caption mr-20" *ngIf="urlCookies!=''"><a href="{{ urlCookies }}" target="_blank" title="Política de Cookies">{{'Política de' | translate}} Cookies</a></span>
  <span class="mat-caption mr-20" *ngIf="urlPagininaInicial!=''"><a href="{{ urlPagininaInicial }}" target="_blank" title="Pagina Inicial"> {{'Volver a la página de la comercializadora' | translate}}</a></span>
  <span class="mat-caption mr-20" *ngIf="urlUso!=''"><a href="{{ urlUso }}" target="_blank" title="Política de Uso">{{'Condiciones de uso' | translate}}</a></span>
  <span class="mat-caption mr-20" *ngIf="urlPoliticaPrivacidad!=''"><a href="{{ urlPoliticaPrivacidad}}" target="_blank" title="Política de Privacidad">{{'Política de de privacidad' | translate}}</a></span>
  <span class="mat-caption mr-20" *ngIf="TPV"><a href="/assets/TPV.DOCX" target="_blank" title="Condiciones Generales TPV">Condiciones Generales TPV</a></span>
</footer>
