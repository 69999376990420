import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-salir',
  templateUrl: './salir.component.html',
  styleUrls: ['./salir.component.scss']
})
export class SalirComponent implements OnInit {
  constructor(private router: Router) {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  ngOnInit() { }
}
