import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../../services/config/global/global.service';
import { Token } from '../../interfaces/token';
import { DatosTitular } from '../../interfaces/datos-titular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  token: any;
  urlApi: string;
  companyName=environment.companyName;
  
  constructor(private http: HttpClient, private _globalService: GlobalService) {
    // Direccion url API
    this.urlApi = this._globalService.getUrlApi();
    this.token = localStorage.getItem('token');
  }

  getLogin(u, p): Observable<Token> {
    const httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: this.token });
    const options = { headers: httpHeaders };
    const body = { usuario: u, password: p };
    return this.http.post<Token>(this.urlApi + 'Login/DatosTitular', body, options);
  }

  getCambioPassword(idCliente, passNueva, token): Observable<Token> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    const options = {
      headers: httpHeaders
    };
    const body = {
      IdCliente: idCliente,
      PassNueva: passNueva
    };
    return this.http.post<Token>(this.urlApi + 'Modificacion/ModificarPassword', body, options);
  }

  getCambioPasswordMarca(idCliente, passNueva, token, marca): Observable<Token> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    const options = {
      headers: httpHeaders
    };
    const body = {
      IdCliente: idCliente,
      PassNueva: passNueva,
      Marca: marca
    };
    return this.http.post<Token>(this.urlApi + 'Modificacion/ModificarPasswordIdMarca', body, options);
  }

  getRecuperarPassword(identificador ,URLApi){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = {
      headers: httpHeaders
    };
    const body = {
      Identificador: identificador
    };
    return this.http.post(URLApi + 'Modificacion/RecuperarPassword', body, options);
   }

  getEmailEleia(identificador ,URLApi){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = {
      headers: httpHeaders
    };
    const body = {
      identificador
    };
    return this.http.post(URLApi + 'Login/GetHiddenMailEleia', identificador, options);
    
  }

  getRecuperarPasswordIdMarca(identificador, marca){
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = {
      headers: httpHeaders
    };
    const body = {
      Identificador: identificador,
      Marca: marca
    };
    return this.http.post(this.urlApi + 'Modificacion/RecuperarPasswordIdMarca', body, options);
  }

}
