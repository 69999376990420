import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  name?: string;
  address?: string;
  email?: string;
  phone?: string;
  urlAvisoLegal?: string;
  urlCookies?: string;
  urlPagininaInicial?: string;
  urlUso?: string;
  urlPoliticaPrivacidad?: string;
  TPV: boolean;

  constructor() { }

  ngOnInit() {
    if(localStorage.getItem("companyName")){
      this.name = localStorage.getItem("companyName");
    }else{
      this.name = environment.companyName;
    }

    this.address = environment.companyAddress;
    this.email = environment.companyEmail;
    this.phone = environment.companyPhone;
    this.urlAvisoLegal = environment.urlAvisoLegal;
    this.urlCookies = environment.urlCookies;
    this.urlPagininaInicial = environment.urlPagininaInicial;
    this.urlUso = environment.urlUso;
    this.urlPoliticaPrivacidad = environment.urlPoliticaPrivacidad;
    if(this.name=='Escandinava de Electricidad'){
      this.TPV=true;
    }else{
      this.TPV=false;
    }
  }

}
