import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MaterialModule } from './material.module';
import { getSpanishPaginator } from './paginator-es';
import { PaginatorI18n } from './PaginatorI18n';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import 'moment/locale/es';
registerLocaleData(localeEs, 'es');
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import * as _moment from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

import { APP_ROUTING } from './app.routes';

// Services
import { FacturasService } from './services/facturas/facturas.service';
import { LoginService } from './services/login/login.service';
import { FuncionesService } from './services/funciones/funciones.service';
import { ContratosService } from './services/contratos/contratos.service';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { LoginDirectoComponent } from './components/logindirecto/logindirecto.component';
import { FacturasComponent } from './components/informacion/facturas/facturas.component';
import { ContratosComponent } from './components/informacion/contratos/contratos.component';
import { PasswordComponent } from './components/usuario/password/password.component';
import { SalirComponent } from './components/usuario/salir/salir.component';
import { ResumenComponent } from './components/usuario/resumen/resumen.component';
import { PerfilComponent } from './components/usuario/perfil/perfil.component';
import { CchComponent } from './components/cch/cch.component';
import { LoginDialogComponent } from './components/dialogs/login-dialog/login-dialog.component';
import { PerfilContratoComponent } from './components/informacion/perfil-contrato/perfil-contrato.component';
import { PerfilCupsComponent } from './components/informacion/perfil-cups/perfil-cups.component';
import { RecordarContrasenaComponent } from './components/recordar-contrasena/recordar-contrasena.component';
import { Error404Component } from './components/errores/error404/error404.component';
import { FormularioNuevaLecturaComponent } from './components/usuario/formularios/formulario-nueva-lectura/formulario-nueva-lectura.component';
import { FormularioPagoRedsysComponent } from './components/usuario/formularios/formulario-pago-redsys/formulario-pago-redsys.component';

// Graficos
import { HighchartsChartModule } from 'highcharts-angular';
import { SpinnerDialogComponent } from './components/dialogs/spinner-dialog/spinner-dialog.component';
// Pipes
import { ContratoSituacionIconPipe } from './pipes/contrato-situacion.pipe';
import { TipoContactoPipe } from './pipes/tipo-contacto.pipe';
import { PerfilIdentidadComponent } from './components/informacion/perfil-identidad/perfil-identidad.component';
//Dialogs
import { CchDialogComponent } from './components/dialogs/cch-dialog/cch-dialog.component';
import { TpvDialogComponent } from './components/dialogs/tpv-dialog/tpv-dialog.component';
import { TranslationComponent } from './translation/translation.component';
import { LuzygasDialogComponent } from './components/dialogs/luzygas-dialog/luzygas-dialog.component';
import { FormularioPopUpSinPagarComponent } from './components/dialogs/pop-up-sinpagar-dialog/pop-up-sinpagar.component';
import { ModificadatosDialogComponent } from './components/dialogs/modificadatos-dialog/modificadatos-dialog.component';
import { ConsultaDialogComponent } from './components/dialogs/consulta-dialog/consulta-dialog.component';
import { MultiloginDialogComponent } from './components/dialogs/multilogin-dialog/multilogin-dialog.component';
import { FacturasMasivasDialogComponent } from './components/dialogs/facturas-masivas-dialog/facturas-masivas-dialog.component';
import { CambiosDatosTitularComponent } from './components/dialogs/cambios-datos-titular/cambios-datos-titular.component';
import { CambiosDatosPagoContactoEnvioComponent } from './components/dialogs/cambios-datos-pago-contacto-envio/cambios-datos-pago-contacto-envio.component';
import { GraficosComponent } from './components/informacion/graficos/graficos.component';
import { ModificacionesComponent } from './components/dialogs/modificaciones/modificiaciones-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    LoginDirectoComponent,
    FacturasComponent,
    ContratosComponent,
    PasswordComponent,
    SalirComponent,
    ResumenComponent,
    PerfilComponent,
    CchComponent,
    LoginDialogComponent,
    CchDialogComponent,
    PerfilContratoComponent,
    PerfilCupsComponent,
    PerfilIdentidadComponent,
    RecordarContrasenaComponent,
    RecordarContrasenaComponent,
    SpinnerDialogComponent,
    LuzygasDialogComponent,
    FormularioPopUpSinPagarComponent,
    Error404Component,
    ContratoSituacionIconPipe,
    TipoContactoPipe,
    TpvDialogComponent,
    TranslationComponent,
    FormularioNuevaLecturaComponent,
    FormularioPagoRedsysComponent,
    ConsultaDialogComponent,
    MultiloginDialogComponent,
    ModificadatosDialogComponent,
    FacturasMasivasDialogComponent,
    CambiosDatosTitularComponent,
    CambiosDatosPagoContactoEnvioComponent,
    GraficosComponent,
    ModificacionesComponent
  ],
  imports: [
    APP_ROUTING,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
      }
    }),
    FormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    MatSnackBarModule,
    HighchartsChartModule,
    MatDatepickerModule,
    ReactiveFormsModule
  ],
  providers: [
    LoginService,
    FacturasService,
    FuncionesService,
    ContratosService,
    { provide: MatPaginatorIntl,
      useClass: PaginatorI18n,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: LOCALE_ID, useValue: 'es' }
  ],
  bootstrap: [AppComponent],
  entryComponents: [LoginDialogComponent, SpinnerDialogComponent, CchDialogComponent, TpvDialogComponent, LuzygasDialogComponent, FormularioPopUpSinPagarComponent, FormularioPagoRedsysComponent, ConsultaDialogComponent,MultiloginDialogComponent, ModificadatosDialogComponent, FacturasMasivasDialogComponent, CambiosDatosTitularComponent, CambiosDatosPagoContactoEnvioComponent,  ModificacionesComponent]
})
export class AppModule { }

export function HttpLoaderFactory(httpClient: HttpClient) {

  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
  }
