<h1 mat-dialog-title>{{'Procesador de pagos' | translate}}</h1>
<div mat-dialog-content [formGroup]="form">
  <table>
    <thead>
      <tr>
        <th>Nº {{'factura' | translate}}</th>
        <th>{{'Fecha Factura' | translate}}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{numeroFactura}}</td>
        <td>{{fechaFactura | date: 'dd/MM/yyyy'}}</td>
      </tr>
      <tr class="total">
        <th>{{'TOTAL' | translate}}</th>
        <td>{{importeTotal | currency:'EUR':'symbol':'':'es'}}</td>
      </tr>
    </tbody>
  </table>
  <div>
    <i class="fal fa-credit-card" matTooltip="Número de tarjeta"></i>
    <mat-form-field class="full-width-field conIcon">
      <input matInput placeholder="Número de tarjeta" required (keyup)="onChangeValue()" formControlName="tarjetaNumeracion" minlength="16" maxlength="16">
    </mat-form-field>
  </div>

  <div>
    <i class="fal fa-user" matTooltip="Nombre titular"></i>
    <mat-form-field class="full-width-field">
      <input matInput placeholder="Nombre titular" fullwidth required (keyup)="onChangeValue()" formControlName="tarjetaNombre" type="text">
    </mat-form-field>
  </div>
  <mat-grid-list cols="3" rowHeight="2:1">
    <mat-grid-tile>
      <mat-form-field class="full-width-field">
        <input matInput placeholder="Mes expiración" required maxlength="2" formControlName="tarjetaMes" (keyup)="onChangeValue()">
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field class="full-width-field">
        <input matInput placeholder="Año expiración" required maxlength="2" formControlName="tarjetaAno" (keyup)="onChangeValue()">
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-form-field class="full-width-field">
        <input matInput placeholder="CCV" required maxlength="3" formControlName="tarjetaCcv" (keyup)="onChangeValue()">
      </mat-form-field>
    </mat-grid-tile>
  </mat-grid-list>
  <br/>
</div>

  <div mat-dialog-actions align="center">
    <!-- <button mat-button (click)="test()">No Thanks</button> -->
    <button mat-button class="pure-material-button-contained" [mat-dialog-close]="tarjetaNumeracion" (click)="onClickPagar()" cdkFocusInitial [disabled]="isDisable"><i class="fal fa-credit-card" matTooltip="Número de tarjeta"></i> Pagar ahora</button>
  </div>
