<div id="loginWrapper" class="mt-30">
  <div fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
    <div fxFlex="20" [fxFlex.md]="40" [fxFlex.sm]="50" [fxFlex.xs]="100">
      <mat-card>
        <img src="assets/img/logo.svg" id="logoEmpresa" alt="Avatar">
        <mat-card-header fxLayoutAlign="center center">
          <mat-card-title *ngIf="!redirigirPrimerLogin">{{'RECUPERAR CONTRASEÑA' | translate }}</mat-card-title>
          <mat-card-title *ngIf="redirigirPrimerLogin">{{"Bienvenido" | translate }}</mat-card-title>
          <mat-card-subtitle *ngIf="!redirigirPrimerLogin">{{'Le enviaremos la contraseña de nuevo.' | translate }}</mat-card-subtitle>
          <mat-card-subtitle *ngIf="redirigirPrimerLogin">{{'Le enviaremos la contraseña por email.' | translate }}</mat-card-subtitle>
        </mat-card-header>
        <form #f="ngForm" novalidate (ngSubmit)="recuperarPassword(f)" class="p-20">
          <mat-form-field fxFlexFill class="pb-20">
            <input type="text" matInput name="identificador" [(ngModel)]="identificador"
            placeholder="{{'DNI / CIF' | translate }}"
              required>
          </mat-form-field>
          <div fxLayout="row" fxLayout.lt-xl="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
            <button type="submit" *ngIf="!redirigirPrimerLogin"  mat-raised-button color="primary" [disabled]="f.invalid">
               {{'Cambiar Contraseña' | translate }}
            </button>
            <button type="submit" *ngIf="redirigirPrimerLogin" mat-raised-button color="primary" [disabled]="f.invalid">
            {{'Enviar Contraseña' | translate }}
           </button>
            <button mat-button [routerLink]="['/login']" *ngIf="!redirigirPrimerLogin">{{'Volver' | translate }}</button>
            <button mat-button [routerLink]="['/login']" *ngIf="redirigirPrimerLogin">{{'Acceder' | translate }}</button>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>

<app-footer></app-footer>
