<app-header></app-header>

<div class="wrapper">

  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px">
    <!-- Datos de titular -->
    <mat-card fxFlex="80" [fxFlex.lt-lg]="100">
      <mat-card-header>
        <mat-card-title *ngIf="!IsAdministrador">{{'DATOS DE TITULAR' | translate}}</mat-card-title>
        <mat-card-title *ngIf="IsAdministrador">{{'DATOS DE ADMINISTRADOR' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'Muestra los datos principales del titular.' | translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <!-- Documento / Email -->
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
          <mat-form-field fxFlex *ngIf="IdentificadorClienteDNI">
            <input matInput placeholder="{{'Documento identificativo' | translate}}" value="{{IdentificadorClienteDNI}}" disabled="PerfilTitularDocumentoIdentificativoDisabled">
            <span class="fal fa-file-alt" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="Email">
            <input matInput placeholder="Email" value="{{Email}}" disabled="PerfilTitularEmailDisabled">
            <span class="fal fa-envelope" matSuffix></span>
          </mat-form-field>
        </div>
        <!-- Razón social -->
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="RazonSocialCliente">
          <mat-form-field fxFlex>
            <input matInput placeholder="{{'Razon Social' | translate}}" value="{{RazonSocialCliente}}" disabled="PerfilTitularRazonSocialDisabled">
            <span class="fal fa-building" matSuffix></span>
          </mat-form-field>
        </div>
        <!-- Nombre / Primer apellido / Segundo apellido -->
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20" *ngIf="!RazonSocialCliente">
          <mat-form-field fxFlex *ngIf="NombreCliente">
            <input matInput placeholder="{{'Nombre' | translate}}" value="{{NombreCliente}}" disabled="PerfilTitularNombreDisabled">
            <span class="fal fa-user-alt" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="Apellido1Cliente">
            <input matInput placeholder="{{'Primer Apellido' | translate}}" value="{{Apellido1Cliente}}" disabled="PerfilTitularApellido1Disabled">
            <span class="fal fa-user-alt" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="Apellido2Cliente">
            <input matInput placeholder="{{'Segundo Apellido' | translate}}" value="{{Apellido2Cliente}}" disabled="PerfilTitularApellido2Disabled">
            <span class="fal fa-user-alt" matSuffix></span>
          </mat-form-field>
        </div>
        <!-- Código Postal / Provincia / Ciudad -->
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
          <mat-form-field fxFlex *ngIf="CodigoPostalCliente">
            <input matInput placeholder="{{'Código Postal' | translate}}" value="{{CodigoPostalCliente}}" disabled="PerfilTitularCodigoPostalDisabled">
            <span class="fal fa-map-pin" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="ProvinciaCliente">
            <input matInput placeholder="{{'Provincia' | translate}}" value="{{ProvinciaCliente}}" disabled="PerfilTitularProvinciaDisabled">
            <span class="fal fa-map-marker-alt" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="CiudadCliente">
            <input matInput placeholder="{{'Ciudad' | translate}}" value="{{CiudadCliente}}" disabled="PerfilTitularCiudadDisabled">
            <span class="fal fa-city" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="DireccionCliente">
            <input matInput placeholder="{{'Dirección' | translate}}" value="{{DireccionCliente}}" disabled="PerfilTitularDireccionDisabled">
            <span class="fal fa-address-book" matSuffix></span>
          </mat-form-field>
        </div>
        <mat-card-actions fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px" class="m-20" *ngIf="MostrarBotonModificiacionDatosPerfil && !IsAdministrador &&   OcultarContactosEleia == false">
          <button mat-button color="primary" (click)="openDialogCambiosDatosTitular()"><i class="far fa-user-edit"></i> {{'Solicitar cambios' | translate}}</button>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>

  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px" *ngIf="TipoCliente == '2'">
      <!-- listado de CUPS -->
      <mat-card fxFlex="80" [fxFlex.lt-lg]="100">
        <div>
          <mat-card-header class="inline-block">
              <mat-card-title>{{'Listado de CUPS' | translate}}</mat-card-title>
              <mat-card-subtitle>{{'Seleccione el CUPS del que desea mostrar datos.' | translate}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="inline-block">
              <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
                  <mat-form-field>
                    <mat-select placeholder="CUPS" (selectionChange)="BusquedaNuevoCups($event.value)"
                      [formControl]="selectedCups" [(ngModel)]="CupsMultipunto" [disabled]="TamanoListadoCupsCliente == 1">
                      <mat-option [value]="Cups.IdCups" *ngFor="let Cups of cupsFiltered">{{Cups.CodigoCups }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-select placeholder="{{'Dirección' | translate}}" (selectionChange)="BusquedaNuevoCups($event.value)"
                        [formControl]="selectedCups" [(ngModel)]="CupsMultipunto" [disabled]="TamanoListadoCupsCliente == 1">
                      <mat-option [value]="Cups.IdCups" *ngFor="let Cups of cupsFiltered">{{Cups.Direccion}}</mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
            </mat-card-content>
        </div>
      </mat-card>
  </div>

  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" *ngIf="IdentificadorRepresentante">
    <!-- Datos de representante -->
    <mat-card fxFlex="80" [fxFlex.lt-lg]="100">
      <mat-card-header>
        <mat-card-title>{{'DATOS DE REPRESENTANTE' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'Muestra los datosdel representante.' | translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
          <mat-form-field fxFlex *ngIf="IdentificadorRepresentante">
            <input matInput placeholder="{{'Identificador' | translate}}" value="{{IdentificadorRepresentante}}" disabled="PerfilRepresentanteIdentificadorDisabled">
            <span class="fal fa-file-alt" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="ColectivoCliente">
            <input matInput placeholder="{{'Colectivo' | translate}}" value="{{ColectivoRepresentante}}" disabled="PerfilRepresentanteColectivoDisabled">
            <span class="fal fa-users" matSuffix></span>
          </mat-form-field>
          <mat-form-field fxFlex *ngIf="NombreRepresentante">
            <input matInput placeholder="{{'Nombre' | translate}}" value="{{NombreRepresentante}}" disabled="PerfilRepresentanteNombreDisabled">
            <span class="fal fa-user-alt" matSuffix></span>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
          <mat-form-field fxFlex *ngIf="ComentarioRepresentante">
            <textarea matInput matTextareaAutosize placeholder="{{'Comentario Representante' | translate}}"
              value="{{ComentarioRepresentante}}"
              disabled="PerfilRepresentanteComentarioDisabled"></textarea>
            <span class="fal fa-city" matSuffix></span>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="end end"
      *ngIf="!PerfilRepresentanteIdentificadorDisabled ||
            !PerfilRepresentanteColectivoDisabled ||
            !PerfilRepresentanteNombreDisabled ||
            !PerfilRepresentanteComentarioDisabled">
        <button mat-button disabled>{{'Solicitar cambios' | translate}}</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div fxLayoutAlign="center stretch" *ngIf="!IsAdministrador">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <!-- Método de pago -->
      <mat-card fxFlex fxFlex="30">
        <mat-card-header>
          <mat-card-title>{{'MÉTODO DE PAGO' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list role="list">
            <mat-list-item *ngIf="MetodosPago?.NombrePagador">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Nombre Completo' | translate}}" value="{{MetodosPago.NombrePagador}}" disabled="PerfilMetodoPagoNombreDisabled">
                <span class="fal fa-file-user" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item *ngIf="MetodosPago?.ColectivoPagador">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Colectivo' | translate}}" value="{{MetodosPago.ColectivoPagador}}" disabled="PerfilMetodoPagoNombreDisabled">
                <span class="fal fa-users" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item *ngIf="MetodosPago?.IdentificadorPagador">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Identificador' | translate}}" value="{{MetodosPago.IdentificadorPagador}}" disabled="PerfilMetodoPagoColectivoDisabled">
                <span class="fal fa-id-card" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item *ngIf="MetodosPago?.TipoCobroPagador">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Tipo Cobro' | translate}}" value="{{MetodosPago.TipoCobroPagador}}" disabled="PerfilMetodoPagoTipoCobroDisabled">
                <span class="fal fa-credit-card" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item *ngIf="MetodosPago?.IBANPagador">
              <mat-form-field fxFlex>
                <input matInput placeholder="IBAN" value="{{MetodosPago.IBANPagador}}" disabled="PerfilMetodoPagoIbanDisabled">
                <span class="fal fa-file-user" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item *ngIf="MetodosPago?.BancoPagador">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Banco' | translate}}" value="{{MetodosPago.BancoPagador}}" disabled="PerfilMetodoPagoBancoDisabled">
                <span class="fal fa-university" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
        <mat-card-actions fxLayout="row" fxLayoutAlign="end end" *ngIf="!PerfilMetodoPagoNombreDisabled ||
            !PerfilMetodoPagoNombreDisabled ||
            !PerfilMetodoPagoColectivoDisabled ||
            !PerfilMetodoPagoTipoCobroDisabled ||
            !PerfilMetodoPagoIbanDisabled ||
            !PerfilMetodoPagoBancoDisabled">
          <button mat-button disabled>{{'DATOS DE CONTACTO' | translate}}</button>
        </mat-card-actions>
      </mat-card>
      <!-- Datos de Contacto -->
      <mat-card fxFlex fxFlex="40">
        <mat-card-header>
          <mat-card-title>{{'DATOS DE CONTACTO' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list role="list" *ngIf="DatosContacto.length > 0">
            <mat-list-item  *ngFor="let contacto of DatosContacto" >
              <div fxLayout="row" fxLayoutGap="20px" class="rowFullWidth smallText" *ngIf="contacto.TipoContacto != OcultaTipoContacto1">
              <mat-form-field fxFlex="45" [fxFlex.lt-lg]="100">
                <input placeholder="{{'Tipo Contacto' | translate}}" matInput value="{{contacto.TipoContacto | tipoContacto}}" disabled="PerfilDatosContactoNombreContactoDisabled">
              </mat-form-field>
              <mat-form-field fxFlex="55" [fxFlex.lt-lg]="100">
                <input placeholder="Valor" matInput value="{{contacto.ValorContacto}}" disabled="PerfilDatosContactoValorDisabled">
                <span *ngIf="contacto.TipoContacto == 'E'" class="fal fa-envelope" matSuffix></span>
                <span *ngIf="contacto.TipoContacto == 'T'" class="fal fa-phone" matSuffix></span>
                <span *ngIf="contacto.TipoContacto == 'M'" class="fal fa-mobile-android-alt" matSuffix></span>
                <span *ngIf="contacto.TipoContacto == 'F'" class="fal fa-facebook-f" matSuffix></span>
              </mat-form-field>
            </div>
            </mat-list-item>
          </mat-list>

        </mat-card-content>
        <mat-card-actions fxLayout="row" fxLayoutAlign="end end" *ngIf="!PerfilDatosContactoNombreContactoDisabled ||
                !PerfilDatosContactoTipoContactoDisabled ||
                !PerfilDatosContactoValorDisabled">
          <button mat-button disabled>{{'Solicitar cambios' | translate}}</button>
        </mat-card-actions>
      </mat-card>

      <!-- Datos de Envío -->
      <mat-card fxFlex fxFlex="30">
        <mat-card-header>
          <mat-card-title>{{'DATOS DE ENVÍO' | translate}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-list role="list">
            <mat-list-item *ngIf="DatosEnvio?.DatosEnvio">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Datos' | translate}}" value="{{DatosEnvio.DatosEnvio}}" disabled="PerfilDatosEnvioDatosDisabled">
                <span class="fal fa-fa-file-user" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item *ngIf="DatosEnvio?.PoblacionEnvio">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Población' | translate}}" value="{{DatosEnvio.PoblacionEnvio}}" disabled="PerfilDatosEnvioPoblacionDisabled">
                <span class="fal fa-city" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item *ngIf="DatosEnvio?.CodigoPostalEnvio">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Código Postal' | translate}}" value="{{DatosEnvio.CodigoPostalEnvio}}" disabled="PerfilDatosEnvioCodigoPostalDisabled">
                <span class="fal fa-map-pin" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item *ngIf="DatosEnvio?.DireccionEnvio">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Dirección' | translate}}" value="{{DatosEnvio.DireccionEnvio}}" disabled="PerfilDatosEnvioDireccionDisabled">
                <span class="fal fa-address-book" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item *ngIf="DatosEnvio?.NumeroEnvio">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Número' | translate}}" value="{{DatosEnvio.NumeroEnvio}}" disabled="PerfilDatosEnvioNumeroDisabled">
                <span class="fal fa-list-ol" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
            <mat-list-item *ngIf="DatosEnvio?.AclaradorEnvio">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'Aclarador' | translate}}" value="{{DatosEnvio.AclaradorEnvio}}" disabled="PerfilDatosEnvioAclaradorDisabled">
                <span class="fal fa-sign" matSuffix></span>
              </mat-form-field>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
        <mat-card-actions fxLayout="row" fxLayoutAlign="end end" *ngIf="!PerfilDatosEnvioDatosDisabled ||
                  !PerfilDatosEnvioPoblacionDisabled || !PerfilDatosEnvioCodigoPostalDisabled || !PerfilDatosEnvioDireccionDisabled ||
                  !PerfilDatosEnvioNumeroDisabled || !PerfilDatosEnvioAclaradorDisabled">
          <button mat-button disabled>{{'Solicitar cambios' | translate}}</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div fxLayoutAlign="center stretch" *ngIf="!IsAdministrador && OcultarContactosEleia == false">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
      <mat-card-actions fxLayout="row" fxLayoutAlign="center end" fxLayoutGap="20px" class="m-20" *ngIf="MostrarBotonModificiacionDatosPerfil && !IsAdministrador">
        <button mat-button color="primary" (click)="openDialogCambiosDatosPagoContactoEnvio()"><i class="far fa-edit"></i> {{'Solicitar cambios pago/contacto/envío' | translate}}</button>
      </mat-card-actions>
    </div>
  </div>
</div>

<app-footer></app-footer>
