<div align="center" mat-dialog-title>
<p *ngIf="!estado"><i class="far fa-exclamation-circle fa-lg"></i></p>
<p *ngIf="estado"><i class="far fa-check-circle fa-lg"></i></p>
<h3>{{ tituloModal }}</h3>
</div>

<mat-dialog-content align="center">
    <p>
        <span class="mat-typography">
            {{ mensajeModal }}
        </span>
    </p>
</mat-dialog-content>