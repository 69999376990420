<mat-card  [fxFlex.lt-md]="200" fxLayout="column" fxLayoutAlign="center center" >
    <h4>{{'Elija una empresa' | translate}}</h4>
    <form [formGroup]="form">
        <mat-select  placeholder="{{'Seleccione una empresa' | translate}}" id="selector" formControlName="option">
            <mat-option [value] ="cliente.companyName" *ngFor="let cliente of data.ClienteExistente"> {{cliente.companyName}}  </mat-option>
        </mat-select>
            <br>
            <br>
        <button mat-stroked-button id="enviar" (click)="loginmultiapi()">{{'ACCEDER' | translate}}</button>
     </form>
</mat-card>