import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from '../../services/config/global/global.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class MailService {

  token: string;
  urlApi: string;
  bodyApiToken: any;
  _URL_: string;

  constructor(
    private http: HttpClient,
    private _globalService: GlobalService) {
    this._URL_ = environment._URL_;
    // Direccion url API
    this.urlApi = this._globalService.getUrlApi();
    this.token = localStorage.getItem('token');
  }

  getEnvioEmail(body): Observable<any> {

    // Para el response en json 
    // const options = {headers: new HttpHeaders({'Content-Type': 'application/json'}), responseType: 'text'};

    // Para response en texto plano
    const options: Object = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'text'
    };

    return this.http.post<any>( this._URL_ + '/libs/envio.php', body, options);
    // return this.http.post<any>('http://192.168.2.17/desarrollo/test/envio.php', body, this._globalService.getHttpHeaders());
  }



}


