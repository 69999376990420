import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'OficinaVirtual';
}



/*
import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'OficinaVirtual';
  public browserLang = 'es';
  constructor(public translate: TranslateService){
    translate.addLangs(['en','es','cat']);
    translate.setDefaultLang('es');

    this.browserLang = translate.getBrowserLang();
    translate.use(this.browserLang.match(/en|cat|es/) ? this.browserLang : 'es');
  }
  public cambiarLenguaje(lang) {
    this.browserLang = lang;
    this.translate.use(lang);
  }
}
*/


