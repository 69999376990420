import { Pipe, PipeTransform } from '@angular/core';

/**
 * Convierte un contrato situación
 * A icono con color para mostrar en tabla.
 */

@Pipe({ name: 'contratoSituacionIcon' })
export class ContratoSituacionIconPipe implements PipeTransform {
  transform(value: string): any {
    let icon: string;
    switch (value) {
      case ('Oferta Enviada'): icon = 'fas fa-cloud-upload-alt'; break;
      case ('Oferta Pendiente'): icon = 'fas fa-clock'; break;
      case ('Rechaza Oferta'):
      case ('Oferta rechazada'): icon = 'fas fa-ban'; break;
      case ('Contrato Firmado'): icon = 'fas fa-file-contract'; break;
      case ('Contrato No Firmado'): icon = 'fas fa-file-times'; break;
      case ('Pendiente Comercial SW'): icon = 'fas fa-user-clock'; break;
      case ('Pendiente Validar SW'): icon = 'fas fa-exchange-alt'; break;
      case ('Scoring Rechazado'): icon = 'fas fa-eye-slash'; break;
      case ('Scoring'): icon = 'fas fa-eye'; break;
      case ('Contrato'): icon = 'fas fa-check'; break;
      case ('Trámite'): icon = 'fal fa-hourglass-end'; break;
      case ('Trámite/Validado'): icon = 'fal fa-hourglass-end'; break;
      case ('Anulado'): icon = 'fas fa-times'; break;
      case ('Baja'): icon = 'fas fa-exclamation-circle'; break;
      case ('Baja por modificación'): icon = 'fas fa-exclamation-circle'; break;
      case ('Oferta'): icon = 'fas fa-percent'; break;
      case ('Confirmado'): icon = 'fas fa-file-certificate'; break;
      case ('No Confirmado'): icon = 'fas fa-certificate'; break;
      case ('No Validado'):
      case ('Descartado'): icon = 'fas fa-thumbs-down'; break;
    }
    return icon;
  }
}
