<mat-toolbar color="primary" class="mat-elevation-z6">

  <img class="logoHeader" src="assets/img/logo.png" id="logoEmpresa" alt="Avatar">

  <span class="{{ margenizquierda }}"></span>

  <button mat-button routerLinkActive="active" [routerLink]="['/resumen']">
    <span fxHide.xs>{{'Inicio' | translate}}</span>
    <span fxHide.gt-xs><span class="fal fa-home"></span></span>
  </button>
  <button mat-button routerLinkActive="active" [routerLink]="['/perfil']">
    <span fxHide.xs>{{'Perfil' | translate}}</span>
    <span fxHide.gt-xs><span class="fal fa-user"></span></span>
  </button>
  <button mat-button routerLinkActive="active" [routerLink]="['/informacion','contratos']">
    <span fxHide.xs>{{'Contratos/Facturas' | translate}}</span>
    <span fxHide.gt-xs><span class="fal fa-file-spreadsheet" matt></span></span>
  </button>
  <!-- <button mat-button routerLinkActive="active" [routerLink]="['/informacion','facturas']"
    *ngIf="tipoCliente !== '3' && tipoCliente !== '2'">
    <span fxHide.xs>{{'Facturas' | translate}}</span>
    <span fxHide.gt-xs><span class="fal fa-file-invoice"></span></span>
  </button> -->
  <button *ngIf="(MostrarConsumosPorHora && tipoCliente == '3') || (MostrarConsumosPorHoraClientes && (tipoCliente == '2' || tipoCliente == '1'))" mat-button routerLinkActive="active" [routerLink]="['/cch']">
    <span fxHide.xs>{{'Consumos por hora' | translate}}</span>
    <span fxHide.gt-xs><span class="fal fa-chart-line"></span></span>
  </button>

  <span class="{{ margenderecha }}"></span>
  <app-translation *ngIf="idiomas"></app-translation>
  <button mat-button [matMenuTriggerFor]="menuRight">
    <span fxHide.xs class="mr-10">{{'Usuario' | translate}}</span>
    <span class="fal fa-ellipsis-v"></span>
  </button>
  <mat-menu #menuRight="matMenu">
    <button *ngIf="MostrarCambioPass" mat-menu-item [routerLink]="['/password']">
      <div fxFlex="20"><span class="fal fa-key"></span></div>
      <div fxFlex="80">{{'Cambiar contraseña' | translate}}</div>
    </button>
    <button mat-menu-item *ngIf="MostrarCierreSesion" [routerLink]="['/salir']">
      <div fxFlex="20"><span class="fal fa-sign-out"></span></div>
      <div fxFlex="80">{{'Cerrar sesión' | translate}}</div>
    </button>
    <span class="mat-caption mr-20" *ngIf="urlPagininaInicial!='' && DesarrolloBonarea"><a href="{{ urlPagininaInicial }}"  title="Salir"> {{'Salir' | translate}}</a></span>
  </mat-menu>
</mat-toolbar>
