<div mat-dialog-content [formGroup]="form">
        <mat-card-title>
            <h4>{{'Modifica tus datos' | translate}}</h4>
        </mat-card-title>
        <mat-toolbar>
            <span>{{'Número de cuenta' | translate}}</span>
        </mat-toolbar>
            <br>
        <mat-form-field class="full-width-field" class="input-full-width">
            <input matInput formControlName="cuenta" id="cuenta">
        </mat-form-field>
            <br>
        <mat-toolbar>
            <span>{{'Nombre del titular' | translate}}</span>
        </mat-toolbar>
            <br>
        <mat-form-field class="full-width-field" class="input-full-width">
              <input matInput formControlName="nombre" id="nombre">
        </mat-form-field>
            <br>
        <mat-toolbar>
            <span>{{'Dirección de titular' | translate}}</span>
        </mat-toolbar>
            <br>
        <mat-form-field class="full-width-field" class="input-full-width">
                <input matInput formControlName="direccion" id="direccion">
         </mat-form-field>
             <br>
        <button mat-stroked-button (click)="onEnviarCambios()" [mat-dialog-close] id="enviar">{{'ENVIAR' | translate}}</button>
</div>
