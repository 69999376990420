<app-header></app-header>

<div class="wrapper">
  <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
    <img src="assets/img/404.svg" id="errorImage"/>
    <span class="mat-headline mt-30 fw-700">{{'Página no encontrada' | translate}}</span>
    <span class="mat-body">{{'La página a la que intentas acceder no existe o no se ha encontrado.' | translate}}</span>
    <button mat-raised-button color="primary" [routerLink]="['/resumen']" class="mt-20">
      <span class="far fa-home fs-18 mr-10"></span>{{'Volver al inicio' | translate}}
    </button>
  </div>
</div>
