import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { PasswordService } from '../../services/password/password.service';
import { FuncionesService } from '../../services/funciones/funciones.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../../components/dialogs/login-dialog/login-dialog.component';
import { ConditionalExpr } from '@angular/compiler';
import { environment } from 'src/environments/environment';
import { GlobalService } from 'src/app/services/config/global/global.service';
import * as CryptoJS from 'crypto-js';
// import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-recordar-contrasena',
  templateUrl: './recordar-contrasena.component.html',
  styleUrls: ['./recordar-contrasena.component.scss']
})
export class RecordarContrasenaComponent implements OnInit {

  marca: any;
  multiapi: any;
  peticionAPIs: any;
  urlApi: string;
  contador = 1;
  intentosExitosos = 0;
  identificador: any;

  redirigirPrimerLogin = environment.redirigirPrimerLogin;

  mensajeCambioPasswordELeia = environment.mensajeCambioPasswordELeia;


  constructor(
    private _funcionesService: FuncionesService,
    private _passwordService: PasswordService,
    public dialog: MatDialog,
    private _loginService: LoginService,
    private route: Router,

    private _globalService: GlobalService,

  ) {
    this.urlApi = this._globalService.getUrlApi();
    this.marca = environment.marca;

    this.multiapi = environment.multiapi;
    this.peticionAPIs = environment.peticionAPIs;
  }

  recuperarPassword(f: NgForm) {
    if (f.valid) {
      if( f.value.identificador.indexOf("'")>= 0){
        if(environment.defaultLang=='cat'){
          this.dialog.open(LoginDialogComponent, {
            width: '400px',
            data: {
              titulo: 'DNI conté caràcters no permesos',
              texto: 'Error restablint la contrasenya',
              estado: true,

            }
          });

        }else{
          this.dialog.open(LoginDialogComponent, {
            width: '400px',
            data: {
              texto: 'DNI contiene caracteres no permitidos.',
              titulo: 'Error al restablecer la contraseña'
            }
          });
        }
      } else {
        if (this.marca == "" || environment.companyName=="Enerxía Galega Máis") {

          if (this.multiapi) {
            this.contador=0;
            this.intentosExitosos=0;

            for (const peticion of this.peticionAPIs) {
              this._passwordService.getRecuperarPassword(f.value.identificador.toUpperCase(), peticion.urlApi).subscribe(
                data => {
                  this.contador++;
                  this.intentosExitosos++;
                  // console.log("entra");
                  // console.log(this.intentosExitosos);
                  // console.log(this.contador);
                  this.MostrarDialogMultiapi();
                },
                err => {
                  this.contador++;
                  // console.log("no entra")
                  this.MostrarDialogMultiapi();
                });

            }

          } else {
            this._passwordService.getRecuperarPassword(f.value.identificador.toUpperCase(), this.urlApi).subscribe(
              data => {
                // this.dialog.open(LoginDialogComponent, {
                //   width: '400px',
                //   data: {
                //     titulo: 'Contraseña restablecida correctamente',
                //     texto: 'Su contraseña se ha restablecido correctamente. En breve recibirá en su correo electrónico su nueva contraseña.',
                //     estado: true,

                //   }
                // });
                if(environment.defaultLang=='cat'){
                  this.dialog.open(LoginDialogComponent, {
                    width: '400px',
                    data: {
                      titulo: 'Contrasenya restablerta correctament',
                      texto: 'La seva contrasenya sha restablert correctament. En breu rebrà en el seu correu electrònic la seva nova contrasenya.',
                      estado: true,

                    }
                  });

                }else{
                 var textoAux='Su contraseña se ha restablecido correctamente. En breve recibirá en su correo electrónico su nueva contraseña.';
                  if(this.mensajeCambioPasswordELeia){
                    var dni=f.value.identificador.toString();
                    // var key = environment.cryptoKey;
                    // var crypto = environment.cryptoIV;
                      var key = CryptoJS.enc.Utf8.parse(this._globalService.getCryptoKey());
                      var iv = CryptoJS.enc.Utf8.parse(this._globalService.getCryptoIV());
                    // console.log(dni);
                     const encrypted = CryptoJS.AES.encrypt(dni, key, {
                      keySize: 256,
                      iv: iv,
                      mode: CryptoJS.mode.CBC,
                      padding: CryptoJS.pad.Pkcs7
                    });
                    var cadena:string=CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
                      //Se añaden las comillas porque la llamada espera un tipo string y no se pasan las comillas por lo que no se interpreta correctamente
                      cadena='"'+cadena+'"';
                      var email;
                  //  console.log(cadena);
                    this._passwordService.getEmailEleia(cadena, this.urlApi).subscribe(async response => {

                      // console.log('hola');
                      // console.log(response);
                     email = this._funcionesService.decodificarToken(response).TextoCifrado;
                    //  console.log(email);
                     textoAux='Su contraseña se ha restablecido correctamente. En breve recibirá en su ' + email + ' su nueva contraseña.';
                     this.dialog.open(LoginDialogComponent, {
                       width: '400px',
                       data: {
                         titulo: 'Contraseña restablecida correctamente',
                         texto: textoAux,
                         estado: true,

                       }
                     });
                      });

                  }else{
                    this.dialog.open(LoginDialogComponent, {
                      width: '400px',
                      data: {
                        titulo: 'Contraseña restablecida correctamente',
                        texto: textoAux,
                        estado: true,

                      }
                    });
                  }

                }

              },
              err => {
                // this.dialog.open(LoginDialogComponent, {
                //   width: '400px',
                //   data: {
                //     titulo: 'Error al restablecer la contraseña',
                //     texto: 'Ha ocurrido un error al restablecer su contraseña. Compruebe si el DNI / CIF introducido es correcto.'
                //   }
                // });
                if(environment.defaultLang=='cat'){
                  this.dialog.open(LoginDialogComponent, {
                    width: '400px',
                    data: {
                      titulo: 'Error restablint la contrasenya',
                      texto: 'Hi ha hagut un error restablint la contrasenya. Comproveu si el DNI / CIF introduït és correcte.',

                    }
                  });

                }else{
                  this.dialog.open(LoginDialogComponent, {
                      width: '400px',
                      data: {
                        titulo: 'Error al restablecer la contraseña',
                        texto: 'Ha ocurrido un error al restablecer su contraseña. Compruebe si el DNI / CIF introducido es correcto.'
                      }
                    });
                }
              });
          }

        } else {

          this._passwordService.getRecuperarPasswordIdMarca(f.value.identificador.toUpperCase(), this.marca).subscribe(
            data => {
              if(environment.defaultLang=='cat'){
                this.dialog.open(LoginDialogComponent, {
                  width: '400px',
                  data: {
                    titulo: 'Contrasenya restablerta correctament',
                    texto: 'La seva contrasenya sha restablert correctament. En breu rebrà en el seu correu electrònic la seva nova contrasenya.',
                    estado: true,
                  }
                });

              }else{
                this.dialog.open(LoginDialogComponent, {
                  width: '400px',
                  data: {
                    titulo: 'Contraseña restablecida correctamente',
                    texto: 'Su contraseña se ha restablecido correctamente. En breve recibirá en su correo electrónico su nueva contraseña.',
                    estado: true,

                  }
                });
              }
            },

            err => {
              // this.dialog.open(LoginDialogComponent, {
              //   width: '400px',
              //   data: {
              //     titulo: 'Error al restablecer la contraseña',
              //     texto: 'Ha ocurrido un error al restablecer su contraseña. Compruebe si el DNI / CIF introducido es correcto.'
              //   }
              // });
              if(environment.defaultLang=='cat'){
                this.dialog.open(LoginDialogComponent, {
                  width: '400px',
                  data: {
                    titulo: 'Error restablint la contrasenya',
                    texto: 'Hi ha hagut un error restablint la contrasenya. Comproveu si el DNI / CIF introduït és correcte.',

                  }
                });

              }else{
                this.dialog.open(LoginDialogComponent, {
                    width: '400px',
                    data: {
                      titulo: 'Error al restablecer la contraseña',
                      texto: 'Ha ocurrido un error al restablecer su contraseña. Compruebe si el DNI / CIF introducido es correcto.'
                    }
                  });
              }
            });

        }
      }
    }
  }

  ngOnInit() {
  }

  MostrarDialogMultiapi() {
    if (this.contador == this.peticionAPIs.length && this.intentosExitosos > 0) {
      if(environment.defaultLang=='cat'){
        this.dialog.open(LoginDialogComponent, {
          width: '400px',
          data: {
            titulo: 'Contrasenya restablerta correctament',
            texto: 'La seva contrasenya sha restablert correctament. En breu rebrà en el seu correu electrònic la seva nova contrasenya.',
            estado: true,

          }
        });

      }else{
        this.dialog.open(LoginDialogComponent, {
          width: '400px',
          data: {
            titulo: 'Contraseña restablecida correctamente',
            texto: 'Su contraseña se ha restablecido correctamente. En breve recibirá en su correo electrónico su nueva contraseña.',
            estado: true,

          }
        });
      }


    } else if(this.contador == this.peticionAPIs.length) {
      // console.log(this.peticionAPIs.length);
      // console.log(this.intentosExitosos + " sad, very sad")
      this.dialog.open(LoginDialogComponent, {
        width: '400px',
        data: {
          titulo: 'Error al restablecer la contraseña',
          texto: 'Ha ocurrido un error al restablecer su contraseña. Compruebe si el DNI / CIF introducido es correcto.',
        }
      });
    }
  }
}
