<form ngNoForm action="{{urltpv}}" method="post" target="_self">
	
	<div class="mat-card">
		<h2 mat-dialog-title>{{'Resumen de facturas' | translate}}</h2>
		<p>{{'Identificador' | translate}}{{factura.NumeroFactura}}</p>
		<p>{{'Cantidad: ' | translate}}{{factura.ImporteTotal | currency:'EUR':'symbol':'':'es'}}</p>
		<input type='hidden' name='Ds_SignatureVersion' value="{{version}}">
		<input type='hidden' name='Ds_MerchantParameters' value="{{params}}">
		<input type='hidden' name='Ds_Signature' value="{{signature}}">
		<!-- <input  class="mat-button-wrapper" value="Pulse para acceder al pago con tarjeta" /> -->
		<button type="submit" color="primary" mat-button="" class="mat-button mat-primary" style="width:100%;"><i class="fal fa-credit-card" matTooltip="Número de tarjeta"></i> Pagar ahora</button>
	</div>
	<!-- <script language=JavaScript>javascript:calc()</script> -->
</form>
