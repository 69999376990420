<!-- <div align="center" mat-dialog-title> -->
<div class="dialog" >
  <div *ngIf="eleccion=='none'">
    <h2 class="mat-card-title">{{'¿Desea acceder al módulo de luz o al de gas?' | translate}}</h2>
    <div class="caja-botones">
      <div class="col" (click)="clickEleccion('luz')" *ngIf="numContratosLuz || entorno == 'Luz'">
        <span class="fa-stack fa-3x">
          <i class="fal fa-file fa-stack-2x"></i>
          <i class="fas fa-lightbulb fa-stack-1x"></i>
        </span>
          <h4>{{'Luz' | translate}}</h4>
      </div>

      <div class="col" (click)="clickEleccion('gas')" *ngIf="numContratosGas || entorno == 'Gas'">
        <span class="fa-stack fa-3x">
          <i class="fal fa-file fa-stack-2x"></i>
          <i class="fas fa-fire fa-stack-1x"></i>
        </span>
        <h4>{{'Gas' | translate}}</h4>
      </div>
    </div>
  </div>
  <div *ngIf="eleccion=='gas' || eleccion=='luz'">
    <app-formulario-nueva-lectura [eleccion]="eleccion"></app-formulario-nueva-lectura>
  </div>
</div>

  <!-- <mat-dialog-content align="center">
    <div  class="mat-typography"> -->
  <!-- </div>
</mat-dialog-content> -->
