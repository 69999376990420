<div mat-dialog-content fxLayout="column" class="dialog" fxLayoutAlign="center stretch">

  <!--
  <mat-card-title>
      {{'Solicitud de cambio de datos titular' | translate}}
  </mat-card-title>
  -->

  <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
    <!-- Método de pago -->
    <div fxFlex fxFlex="35">
      <mat-card-header>
        <mat-card-title>{{'MÉTODO DE PAGO' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list role="list">
          <mat-list-item *ngIf="MetodosPago?.NombrePagador">
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Nombre Completo' | translate}}" value="{{MetodosPago.NombrePagador}}" [(ngModel)]="NombrePagador">
              <span class="fal fa-file-user" matSuffix></span>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item *ngIf="MetodosPago?.ColectivoPagador">
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Colectivo' | translate}}" value="{{MetodosPago.ColectivoPagador}}" [(ngModel)]="ColectivoPagador">
              <span class="fal fa-users" matSuffix></span>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item *ngIf="MetodosPago?.IdentificadorPagador">
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Identificador' | translate}}" value="{{MetodosPago.IdentificadorPagador}}" [(ngModel)]="IdentificadorPagador">
              <span class="fal fa-id-card" matSuffix></span>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item *ngIf="MetodosPago?.TipoCobroPagador">
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Tipo Cobro' | translate}}" value="{{MetodosPago.TipoCobroPagador}}" [(ngModel)]="TipoCobroPagador">
              <span class="fal fa-credit-card" matSuffix></span>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item *ngIf="MetodosPago?.IBANPagador">
            <mat-form-field fxFlex>
              <input matInput placeholder="IBAN" value="{{MetodosPago.IBANPagador}}" [(ngModel)]="IBANPagador">
              <span class="fal fa-file-user" matSuffix></span>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item *ngIf="MetodosPago?.BancoPagador">
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Banco' | translate}}" value="{{MetodosPago.BancoPagador}}" [(ngModel)]="BancoPagador">
              <span class="fal fa-university" matSuffix></span>
            </mat-form-field>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </div>
    <!-- Datos de Contacto -->
    <div fxFlex fxFlex="31">
      <mat-card-header>
        <mat-card-title>{{'DATOS DE CONTACTO' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list role="list" >
          <mat-list-item  *ngFor="let contacto of DatosContacto;let i = index">
            <div fxLayout="row" fxLayoutGap="20px" class="rowFullWidth smallText">
            <mat-form-field fxFlex="36" [fxFlex.lt-lg]="100">
              <input placeholder="{{'Tipo Contacto' | translate}}" matInput value="{{contacto.Tipo | tipoContacto}}" disabled>
            </mat-form-field>
            <mat-form-field fxFlex="64" [fxFlex.lt-lg]="100">
              <input placeholder="Valor" matInput value="{{DatosContacto[i].Valor}}" [(ngModel)]="DatosContacto[i].Valor">
              <span *ngIf="contacto.Tipo == 'E'" class="fal fa-envelope" matSuffix></span>
              <span *ngIf="contacto.Tipo == 'T'" class="fal fa-phone" matSuffix></span>
              <span *ngIf="contacto.Tipo == 'M'" class="fal fa-mobile-android-alt" matSuffix></span>
              <span *ngIf="contacto.Tipo == 'F'" class="fal fa-facebook-f" matSuffix></span>
            </mat-form-field>
          </div>
          </mat-list-item>
        </mat-list>

      </mat-card-content>
    </div>

    <!-- Datos de Envío -->
    <div fxFlex fxFlex="34">
      <mat-card-header>
        <mat-card-title>{{'DATOS DE ENVÍO' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list role="list">
          <mat-list-item *ngIf="DatosEnvio?.DatosEnvio">
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Datos' | translate}}" value="{{DatosEnvio.DatosEnvio}}" [(ngModel)]="DatosEnvio">
              <span class="fal fa-fa-file-user" matSuffix></span>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item *ngIf="DatosEnvio?.PoblacionEnvio">
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Población' | translate}}" value="{{DatosEnvio.PoblacionEnvio}}" [(ngModel)]="PoblacionEnvio">
              <span class="fal fa-city" matSuffix></span>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item *ngIf="DatosEnvio?.CodigoPostalEnvio">
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Código Postal' | translate}}" value="{{DatosEnvio.CodigoPostalEnvio}}" [(ngModel)]="CodigoPostalEnvio">
              <span class="fal fa-map-pin" matSuffix></span>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item *ngIf="DatosEnvio?.DireccionEnvio">
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Dirección' | translate}}" value="{{DatosEnvio.DireccionEnvio}}" [(ngModel)]="DireccionEnvio">
              <span class="fal fa-address-book" matSuffix></span>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item *ngIf="DatosEnvio?.NumeroEnvio">
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Número' | translate}}" value="{{DatosEnvio.NumeroEnvio}}" [(ngModel)]="NumeroEnvio">
              <span class="fal fa-list-ol" matSuffix></span>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item *ngIf="DatosEnvio?.AclaradorEnvio">
            <mat-form-field fxFlex>
              <input matInput placeholder="{{'Aclarador' | translate}}" value="{{DatosEnvio.AclaradorEnvio}}" [(ngModel)]="AclaradorEnvio">
              <span class="fal fa-sign" matSuffix></span>
            </mat-form-field>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </div>
  </div>

  <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayout.lt-lg="column" fxLayoutGap="20px">
    <div fxFlex fxFlex="50">
      <!-- CAPTCHA-->
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
        <mat-form-field fxFlex>
          <input matInput placeholder="{{'Captcha' | translate}}: {{varOne}} + {{varTwo}}" value="" [(ngModel)]="captcha">
          <span class="fal fa-calculator-alt" matSuffix></span>
        </mat-form-field>
      </div>
    </div>
    <div fxFlex fxFlex="50">
      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px" class="m-20">
        <mat-card-actions fxLayout="row" fxLayoutAlign="center end" fxLayoutGap="20px" class="m-20">
          <button mat-button color="primary" (click)="onEnviarCambios()" 
          [disabled]="resultSumOneTwo!=captcha"
          [mat-dialog-close]><i class="far fa-mail-bulk"></i> {{'Enviar solicitud de cambios' | translate}}</button>
        </mat-card-actions>
      </div>
    </div>
  </div>
</div>