import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FacturasService } from '../../../services/facturas/facturas.service';
import { LoginService } from '../../../services/login/login.service';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { Factura } from '../../../interfaces/factura';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../../../components/dialogs/login-dialog/login-dialog.component';
import { TpvDialogComponent } from '../../../components/dialogs/tpv-dialog/tpv-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { dateFormat } from 'highcharts';
import { FormularioPagoRedsysComponent } from '../../usuario/formularios/formulario-pago-redsys/formulario-pago-redsys.component';

@Component({
  selector: 'app-facturas',
  templateUrl: './facturas.component.html',
  styleUrls: ['./facturas.component.scss']
})
export class FacturasComponent implements OnInit {
  facturas: Factura[];
  mostrarSpinner = true;
  textoModal: string;
  arraySeleccionados: any = [];
  companyName: any;
  isLoading = true;
  loadingSpinnerDescarga = false;
  btnDescargaFacturasMasivaVisible = true;
  btnDescargaFacturasExcelVisible = true;
  MostrarBotonFacturasImpagadas: any;
  loadingSpinnerDescargaExcel = false;
  arrayFacturasSeleccionadas: any = [];
  textoTablaFacturasVacia: boolean = false;
  MostrarDeuda = environment.MostrarDeuda;
  HabilitarTPV = environment.HabilitarTPV;
  PrefijoNumeracionFacturas = environment.PrefijoNumeracionFacturas;
  OcultarFacturasConecta2DeAntesDel31x11x19: boolean;
  MostrarFacturaExcel = environment.MostrarFacturaExcel;
  SimplificacionSerieFactura = environment.SimplificacionSerieFactura;
  NombreEmpresa = environment.companyName;
  internalApiUrl:string = '';
  facturasImpagadas: boolean;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = [];


  dataSource = new MatTableDataSource<Factura>();
  dataSourceAux = new MatTableDataSource<Factura>();
  selection = new SelectionModel<Factura>(true, []);

  selectFilter = new FormControl();
  EntornoFilter = new FormControl();
  NumeroFacturaFilter = new FormControl();
  FechaFacturaFilter = new FormControl();
  ImporteTotalFilter = new FormControl();
  EstadoFilter = new FormControl();
  DescargarFilter = new FormControl();
  PagarFilter = new FormControl();
  FechaHastaFilter = new FormControl();
  FechaVencimientoFilter = new FormControl();
  FechaLimitePagoFilter = new FormControl();
  FechaDesdeFilter = new FormControl();

  MostrarAdministradorListadoContratoEntorno: Boolean ;

  filteredValues = {
    Entorno: '',
    NumeroFactura: '',
    FechaFactura: '',
    FechaHasta: '',
    FechaDesde: '',
    FechaVencimiento: '',
    FechaLimitePago: '',
    ImporteTotal: '',
    Estado: '',
    Descargar: '',
    Pagar: ''
  };

  // TODO TPV
  tarjetaNumeracion: string;
  tarjetaNombre: string;
  tarjetaMes: string;
  tarjetaAno: string;
  tarjetaCcv: string;
  dialogRef: any;
  tokenvalidado: any;

  fechaLimite: Date;
  fechaTest: Date;

  constructor(
    private router: Router,
    private _loginService: LoginService,
    private _facturasService: FacturasService,
    private _funcionesService: FuncionesService,
    public dialog: MatDialog,
    private _activatedRoute: ActivatedRoute
  ) {

    this.OcultarFacturasConecta2DeAntesDel31x11x19 = environment.OcultarFacturasConecta2DeAntesDel31x11x19;
    this.fechaLimite = new Date("2019-11-30T12:00:00-06:30");
    this.fechaTest = new Date("2020-11-30T00:00:00");
    this.MostrarAdministradorListadoContratoEntorno = environment.MostrarAdministradorListadoContratoEntorno;
  }

  redsysdialog(factura){
    this.dialogRef=this.dialog.open(FormularioPagoRedsysComponent, {
      //posibilidad de pasar path de la API desde aqui
      data:{
        'factura': factura,
        'companyName': this.NombreEmpresa,
        'token': localStorage.getItem('token'),
        'internalApiUrl': this.internalApiUrl,
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }
  ngOnInit() {

    this.displayedColumns.push('select');
    (this.MostrarFacturaExcel) ? this.displayedColumns.push('Precios') : null; // Si MostrarFacturaExcel es true mostramos una nueva columna 'Precios' en el listado de facturas
    (this.MostrarAdministradorListadoContratoEntorno) ? this.displayedColumns.push('Entorno') : null;
    this.displayedColumns.push('Factura');
    this.displayedColumns.push('FechaFactura');
    this.displayedColumns.push('PeriodoFacturacion');
    if(environment.mostrarFechaVencimientoFactura) {
      this.displayedColumns.push('FechaVencimiento');
    }

    if(environment.mostrarLimitePagoFactura) {
      this.displayedColumns.push('FechaLimitePago');
    }

    this.displayedColumns.push('ImporteTotal');
    if(this.MostrarDeuda){
      this.displayedColumns.push('Estado');
    }
    this.displayedColumns.push('Descargar');
    (this.HabilitarTPV) ? this.displayedColumns.push('Pagar') : null; // TPV

    this.facturasImpagadas = false;
    this.MostrarBotonFacturasImpagadas = environment.MostrarBotonFacturasImpagadas;

    // Listado facturas
    this._activatedRoute.params.subscribe(params => {
      var split = params.idcontrato;
      var splitted = split.split("&", 3);
      this._facturasService
        .getListadoFacturas((localStorage.getItem('TipoCliente') === '3' || localStorage.getItem('TipoCliente') === '2') ? splitted[0] : localStorage.getItem('idCliente'), '', '','',splitted[1],splitted[2])
        .subscribe(
          data => {

            this.isLoading = false;
            this.facturas = this._funcionesService.decodificarToken(data);
            this.facturas.forEach(element => {
              if(this.SimplificacionSerieFactura && isNaN(Number(element.Serie)) ){
                var year: number = +element.FechaFactura.substring(0,4);
                if(year >= 2021){
                  element.Serie = element.Serie.replace(/\D/g,'');
                }
              }
            });
            this.dataSource = new MatTableDataSource<Factura>(this.facturas);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.mostrarSpinner = false;
            this.customFilter();
          },
          err => {
            this.isLoading = false;
            this.textoModal = this._funcionesService.decodificarToken(err.error)['0'].Error;
            this.mostrarSpinner = false;
            this.dialog.open(LoginDialogComponent, {
              width: '400px',
              data: {
                texto: this.textoModal,
                titulo: 'Error en facturas'
              }
            });
          }
        );
  });

    ////////////////////////////////////////////////////////////////////////////////
  }
  isNumber(value) {
    return !Number.isNaN(Number(value));
  }
  customFilter() {
    this.EntornoFilter.valueChanges.subscribe(EntornoFilterValue => {
      this.filteredValues['Entorno'] = EntornoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.NumeroFacturaFilter.valueChanges.subscribe(
      NumeroFacturaFilterValue => {
        this.filteredValues['NumeroFactura'] = NumeroFacturaFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      }
    );
    this.FechaFacturaFilter.valueChanges.subscribe(FechaFacturaFilterValue => {
      this.filteredValues['FechaFactura'] = FechaFacturaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaDesdeFilter.valueChanges.subscribe(FechaDesdeFilterValue => {
      this.filteredValues['PeriodoFacturacion'] = FechaDesdeFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaHastaFilter.valueChanges.subscribe(FechaHastaFilterValue => {
      this.filteredValues['PeriodoFacturacion'] = FechaHastaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaVencimientoFilter.valueChanges.subscribe(FechaVencimientoFilterValue => {
      this.filteredValues['FechaVencimiento'] = FechaVencimientoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaLimitePagoFilter.valueChanges.subscribe(FechaLimitePagoFilterValue => {
      this.filteredValues['FechaLimitePago'] = FechaLimitePagoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.ImporteTotalFilter.valueChanges.subscribe(ImporteTotalFilterValue => {
      this.filteredValues['ImporteTotal'] = ImporteTotalFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.EstadoFilter.valueChanges.subscribe(EstadoFilterValue => {
      this.filteredValues['Estado'] = EstadoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.DescargarFilter.valueChanges.subscribe(DescargarFilterValue => {
      this.filteredValues['Descargar'] = DescargarFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.PagarFilter.valueChanges.subscribe(PagarFilterValue => {
      this.filteredValues['Pagar'] = PagarFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  customFilterPredicate() {
    const myFilterPredicate = function (data: Factura, filter: string): boolean {
      const searchString = JSON.parse(filter);
      return (
        data.Entorno.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Entorno.toLowerCase()) !== -1 &&
        data.NumeroFactura.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.NumeroFactura.toLowerCase()) !== -1 &&
        data.FechaFactura.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaFactura.toLowerCase()) !== -1 &&
        data.ImporteTotal.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.ImporteTotal.toLowerCase()) !== -1 &&
        data.FechaDesde.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaDesde.toLowerCase()) !== -1 &&
        data.FechaHasta.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaHasta.toLowerCase()) !== -1 &&
        data.FechaVencimiento.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaVencimiento.toLowerCase()) !== -1 &&
        data.FechaLimitePago.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaLimitePago.toLowerCase()) !== -1
        // data..toString()
        //   .trim()
        //   .toLowerCase()
        //   .indexOf(searchString.Estado.toLowerCase()) !== -1 &&
        // data.Descarga.toString()
        //   .trim()
        //   .toLowerCase()
        //   .indexOf(searchString.Descarga.toLowerCase()) !== -1 &&
        // data.Pagar.toString()
        //   .trim()
        //   .toLowerCase()
        //   .indexOf(searchString.Pagar.toLowerCase()) !== -1
      );
    };
    return myFilterPredicate;
  }


  descargarSeleccionados() {
    this.btnDescargaFacturasMasivaVisible = false;
    this.loadingSpinnerDescarga = true;
    this.arrayFacturasSeleccionadas = [];
    for (const i of this.selection.selected) {
      if (i.IdContratoDocumento != '') {
        this.arrayFacturasSeleccionadas.push(i.IdContratoDocumento);
      }
    }
    // console.log(this.arrayFacturasSeleccionadas);
    this._facturasService.getDescargaFacturasZip(this.arrayFacturasSeleccionadas).subscribe(
      data => {
        this.loadingSpinnerDescarga = false;
        this.btnDescargaFacturasMasivaVisible = true;
        const zip = new Blob([data], { type: 'application/zip' });
        const zipURL = URL.createObjectURL(zip);
        window.open(zipURL);
      });
  }

  descargarSeleccionadosExcel() {
    this.btnDescargaFacturasExcelVisible = false;
    this.loadingSpinnerDescargaExcel = true;
    let array: any = [];
    for (let i = 0; i < this.selection.selected.length; i++) {
      array = array.concat(this.selection.selected[i].IdFacturaVentaCabecera);
    }
    this._facturasService.getDesgloseFacturasExcel(array, true).subscribe(
      data => {
        if(data == "error"){
          this.router.navigate(['/salir']);
        }
        this.loadingSpinnerDescargaExcel = false;
        this.btnDescargaFacturasExcelVisible = true;
        const excel = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const urlExcel = window.URL.createObjectURL(excel);
        window.open(urlExcel);
      });
  }

  getMilisegundosDeFecha(value){
    return Date.parse(value)
  }

  mostrarSoloFacturasImpagadas() {

    this.facturasImpagadas = !this.facturasImpagadas;

    // console.log(this.facturasImpagadas);

    if (this.facturasImpagadas){
      // guardamos temporalmente los valores antes de mostrar solo los datos filtratos por las facturas impagadas
      this.dataSourceAux.data = this.dataSource.data;
      let arrayFiltradoImpagadas: any = [];

      this.dataSource.data.forEach((value, index) => {

        if(!value.Estado){ arrayFiltradoImpagadas.push(value); }

      });

      this.dataSource.data = arrayFiltradoImpagadas;

      this.textoTablaFacturasVacia = (this.dataSource.data.length > 0) ? false : true;

      // console.log(this.textoTablaFacturasVacia);

    } else {

      // recuperamos el listado previo del filtrado de impagadas
      this.dataSource.data = this.dataSourceAux.data;

    }

  }

  descargaFactura(datos: any) {
    // console.log(datos);
    if(this.NombreEmpresa=='Innovación Energía del Norte'){
      this._facturasService
      .getDescargaFactura(datos.IdContratoDocumento)
      .subscribe(data => {
        const file = new Blob([data], { type: 'application/pdf' });
        var link = document.createElement('a');
        link.href=  URL.createObjectURL(file);
        link.download = datos.SerieNumFactura;
        link.click();
        // comprobamos el navegador en funcion de ello se abre el Blob diferente para IE y para los demas
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(file, 'file.pdf');
        // } else {
        //   window.open(fileURL);
        // }

      });

    }else{
      this._facturasService
      .getDescargaFactura(datos.IdContratoDocumento)
      .subscribe(data => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        // comprobamos el navegador en funcion de ello se abre el Blob diferente para IE y para los demas
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(file, 'file.pdf');
        // } else {
        window.open(fileURL);
        // }

      });
    }

  }

  descargaPrecios(datos: any) {
    this._facturasService
      .getDescargaPrecios(datos.IdDocumentoDescargable)
      .subscribe(data => {
        // const file = new Blob([data], { type: 'application/vnd.ms-excel' });
        const file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);

        // comprobamos el navegador en funcion de ello se abre el Blob diferente para IE y para los demas
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(file, 'file.xlsx');
        // } else {
           window.open(fileURL);
        // }

      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => {
        if(!this.OcultarFacturasConecta2DeAntesDel31x11x19
          || this.getMilisegundosDeFecha(row.FechaFactura) > this.fechaLimite.getTime()){
            this.selection.select(row);
          }else{
              // console.log('No entra');
          }
         });
  }

  // llamada TPV REDSYS
  hacerPagoFactura(importeTotal, fechaFactura, numeroFactura): void {
    // console.log(importeTotal);
    // console.log(fechaFactura);
    // console.log(numeroFactura);
    this.dialogRef = this.dialog.open(TpvDialogComponent, {
      width: '430px',
      height: '520px',
      data: {tarjetaNumeracion: this.tarjetaNumeracion, tarjetaNombre: this.tarjetaNombre, importeTotal: importeTotal, fechaFactura: fechaFactura, numeroFactura: numeroFactura}
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // console.log(result);
      if (result !== undefined) {


        this._facturasService
        .postPagoTpvRedsys(result.tarjetaNombre, result.tarjetaNumeracion, result.tarjetaMes, result.tarjetaAno, result.tarjetaCcv, importeTotal, numeroFactura)
        .subscribe(async data => {
          this.isLoading = false;
        },
          err => {
            this.dialog.open(LoginDialogComponent, {
              width: '400px',
              data: {
                texto: 'Ha ocurrido un problema en proceso del pago.',
                titulo: 'Error en el pago',
                estado: false
              }
            });
            this.isLoading = false;
          }
        );
      } else {
        // console.log('Formulario cancelado');
      }
    });
  }

}
