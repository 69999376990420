import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ValidacionesCchService {
    CchAgregables = false;

    constructor() { }
}
