import { Component, HostListener  } from '@angular/core';
import { LoginService } from '../../services/login/login.service';
import { FuncionesService } from '../../services/funciones/funciones.service';
import { environment } from '../../../environments/environment';
import { TranslationComponent } from '../../translation/translation.component';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  DesarrolloBonarea: boolean;
  idCliente: any;
  tipoCliente: string;
  margenizquierda: any;
  margenderecha: any;
  MostrarConsumosPorHora: any;
  MostrarConsumosPorHoraClientes: any;
  idiomas: boolean;
  MostrarCierreSesion: boolean;
  MostrarCambioPass: boolean;
  urlPagininaInicial: string;

  constructor(
    private _loginService: LoginService,
    private _funcionesService: FuncionesService
  ) {
      this._loginService.token = localStorage.getItem('token');
      this.tipoCliente = localStorage.getItem('TipoCliente');

      switch (environment.alinearmenusuperior) {
        case 'derecha': {
          this.margenizquierda = 'fill-space';
          this.margenderecha = '';
          break;
        }
        case 'izquierda': {
          this.margenderecha = 'fill-space';
          this.margenizquierda = '';
          break;
      }
      case 'centro': {
        this.margenderecha = 'fill-space';
        this.margenizquierda = 'fill-space';
        break;
    }
  }
}

ngOnInit() {
  this.MostrarConsumosPorHora = environment.MostrarConsumosPorHora;
  this.MostrarConsumosPorHoraClientes = environment.MostrarConsumosPorHoraClientes;
  this.MostrarCierreSesion = environment.MostrarCierreSesion;
  this.MostrarCambioPass = environment.MostrarCambioPass;
  this.idiomas = environment.idiomas;
  this.DesarrolloBonarea = environment.DesarrolloBonarea;
  this.urlPagininaInicial = environment.urlPagininaInicial;
}
}
