<app-header *ngIf="!monopunto"></app-header>

<div class="wrapper" *ngIf="!monopunto">
  <div fxLayoutAlign="center stretch" fxLayout.lt-lg="column" fxLayoutGap="20px">
    <div fxFlex="80" [fxFlex.lt-lg]="100" fxLayoutGap="20px">

      <mat-card class="of p-0">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container> -->
          <!-- Entorno -->
          <ng-container matColumnDef="Entorno">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header> {{'Entorno' | translate}}</span>
              <input class="filter-input" matInput [formControl]="EntornoFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">
              <span class="far fa-lightbulb fa-lg padding" *ngIf="element.Entorno === 'Luz'" aria-hidden="true"
                matTooltip="Luz"></span>
              <span class="far fa-fire fa-lg padding" *ngIf="element.Entorno === 'Gas'" aria-hidden="true"
                matTooltip="Gas"></span>
            </td>
          </ng-container>
          <!-- Numero finca -->
          <ng-container matColumnDef="NumFinca" >
            <th mat-header-cell *matHeaderCellDef [hidden]="tipocliente == 1 || tipocliente == 2 || tipocliente == 3">
              <span mat-sort-header>{{'Número Finca' | translate}}</span>
              <input class="filter-input" matInput [formControl]="NumFincaFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="tipocliente == 1 || tipocliente == 2 || tipocliente == 3">{{element.NumFinca}}</td>
          </ng-container>
          <!-- Contrato -->
          <ng-container matColumnDef="CodigoContrato" *ngIf="!agrupacontratoscups">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Contrato' | translate}}</span>
              <input class="filter-input" matInput [formControl]="ContratoFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="[ '/informacion', 'perfil', 'contrato', element.IdContrato ]"
                matTooltip="Ver perfil de Contrato">
                {{PrefijoNumeracionContratos}} {{element.CodigoContrato}}
              </a>
            </td>
          </ng-container>
          <!-- CUPS -->
          <ng-container matColumnDef="Cups">
            <th mat-header-cell *matHeaderCellDef filter-by="element.Cups">
              <span mat-sort-header> CUPS</span>
              <input class="filter-input" matInput [formControl]="CupsFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">
              <a color="primary" [routerLink]="[ '/informacion', 'perfil', 'cups', element.IdCups ]"
                matTooltip="Ver perfil de CUPS">
                {{element.Cups}}
              </a>
            </td>
          </ng-container>
          <!-- Dirección -->
          <ng-container matColumnDef="Direccion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>>{{'Dirección' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{element.Direccion}}</td>
          </ng-container>
          <!-- Fecha Alta -->
          <ng-container matColumnDef="FechaAlta">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Fecha Alta' | translate}}</span>
              <input class="filter-input" matInput [formControl]="FechaAltaFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">{{element.FechaAlta | date: 'dd/MM/yyyy'}}</td>
          </ng-container>
          <!-- Fecha Vencimiento -->
          <ng-container matColumnDef="FechaVto">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-header-cell>{{'Fecha Vencimiento' | translate}}</span>
              <input class="filter-input" matInput [formControl]="FechaVencimientoFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">{{element.FechaVto | date: 'dd/MM/yyyy'}}</td>
          </ng-container>
          <!-- Fecha Baja -->
          <ng-container matColumnDef="FechaBaja">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Fecha Baja' | translate}}</span>
              <input class="filter-input" matInput [formControl]="FechaBajaFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">{{element.FechaBaja | date: 'dd/MM/yyyy'}}</td>
          </ng-container>
          <!-- Situación -->
          <ng-container matColumnDef="ContratoSituacion">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Situación' | translate}}</span>
              <input class="filter-input" matInput [formControl]="SituacionFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">
              <span matTooltip="{{ element.ContratoSituacion }}"
                class="{{ element.ContratoSituacion | contratoSituacionIcon }}"></span>
            </td>
          </ng-container>
          <!-- Identidad -->
          <ng-container matColumnDef="Identidad">
            <th mat-header-cell *matHeaderCellDef [hidden]="tipocliente == 2 || tipocliente == 1">
              <span mat-sort-header *ngIf="!CambiarTitularElekluz">{{'Identidad' | translate}}</span>
              <span mat-sort-header *ngIf="CambiarTitularElekluz">{{'CIF' | translate}}</span>
              <input class="filter-input" matInput [formControl]="IdentidadFilter" placeholder="{{'Filtrar' | translate}}" />

              <!-- [routerLink]="[ '/informacion', 'perfil', element.IdCliente+'&'+element.IdContrato+'&'+element.Cups+'&'+element.Identidad ]" -->
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="tipocliente == 2 || tipocliente == 1">
              <a color="primary" [routerLink]="[ '/informacion', 'perfil', 'cliente', element.IdCliente+'&'+element.IdContrato ]"
                matTooltip="Ver perfil de Cliente">
                {{element.Identidad}}
              </a>
            </td>
          </ng-container>
          <!-- Nombre -->
          <ng-container matColumnDef="Nombre">
            <th mat-header-cell *matHeaderCellDef [hidden]="tipocliente == 2 || tipocliente == 1 || !EleiaNombreAdministradorFincas">
              <span mat-sort-header >{{'Nombre' | translate}}</span>
              <input class="filter-input" matInput [formControl]="NombreFilter" placeholder="{{'Filtrar' | translate}}" />

              <!-- [routerLink]="[ '/informacion', 'perfil', element.IdCliente+'&'+element.IdContrato+'&'+element.Cups+'&'+element.Identidad ]" -->
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="tipocliente == 2 || tipocliente == 1 || !EleiaNombreAdministradorFincas">
                {{element.NombreCliente}}
            </td>
          </ng-container>

          <!-- DireccionSuministro -->
          <ng-container matColumnDef="DireccionSuministro">
            <th mat-header-cell *matHeaderCellDef [hidden]="tipocliente == 2 || tipocliente == 1">
              <span mat-sort-header>{{'Dirección Suministro' | translate}}</span>
              <input class="filter-input" matInput [formControl]="DireccionSuministroFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="tipocliente == 2 || tipocliente == 1">
                {{element.DireccionSuministro}}
            </td>
          </ng-container>


          <!-- Denominacion -->
          <ng-container matColumnDef="DenominacionCliente">
            <th mat-header-cell *matHeaderCellDef [hidden]="tipocliente == 2 || tipocliente == 1">
              <span mat-sort-header>{{'Denominacion' | translate}}</span>
              <input class="filter-input" matInput [formControl]="DenominacionClienteFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element" [hidden]="tipocliente == 2 || tipocliente == 1">
              <a color="primary" [routerLink]="[ '/informacion', 'perfil', 'cliente', element.IdCliente ]"
                matTooltip="Ver perfil de Cliente">
                {{element.DenominacionCliente}}
              </a>
            </td>
          </ng-container>
          <!-- Tarifa -->
          <ng-container matColumnDef="Tarifa">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Tarifa' | translate}}</span>
              <input class="filter-input" matInput [formControl]="TarifaFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">{{element.Tarifa}}</td>
          </ng-container>
          {{element}}
          <!-- Deuda -->
          <ng-container matColumnDef="Deuda">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header>{{'Deuda' | translate}}</span>
              <input class="filter-input" matInput [formControl]="DeudaFilter" placeholder="{{'Filtrar' | translate}}" />
            </th>
            <td mat-cell *matCellDef="let element">
              <span matTooltip="Tiene deuda pendiente en las facturas" class="alert" style="color:red;" *ngIf="element.Deuda > 0">
                {{element.Deuda | currency:'EUR':'symbol':'':'es'}}
              </span>
              <span matTooltip="Sin deuda" class="alert"><i class="fal fa-check-circle fa-lg" *ngIf="element.Deuda==0"></i></span>
            </td>
          </ng-container>


          <!-- Graficos Column -->
          <ng-container matColumnDef="Graficos" *ngIf="MostrarListadoContratoBotonGraficos && tipocliente == 3">
            <th mat-header-cell *matHeaderCellDef>{{'Gráficos' | translate}}</th>
            <td mat-cell *matCellDef="let element" >
              <a mat-raised-button color="secondary"
                [routerLink]="[ '/informacion', 'graficos', element.IdCups ]"
                matTooltip="Ver gráfico">
                <span class="fal fa-chart-bar"></span>
              </a>
            </td>
          </ng-container>

          <!-- Boton Facturas Admin Fincas -->
          <ng-container matColumnDef="Facturas">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Facturas' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              <a mat-raised-button color="primary" *ngIf="element.NumFacturas > 0"
                [routerLink]="[ '/informacion', 'facturas', element.IdContrato+'&'+element.Cups+'&'+element.Identidad ]"
                matTooltip="Ver {{ element.NumFacturas }} facturas" matBadge="{{ element.NumFacturas }}"
                matBadgeColor="warn">
                <span class="fal fa-layer-group fa-lg"></span>
              </a>
            </td>
          </ng-container>

           
          <!-- Han pedido ocultarlo, lo dejo comentado por si en el futurolo vuelven a pedir :))

             <ng-container matColumnDef="Ciudad" *ngIf="DesarrolloCatgas">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header> {{'Ciudad' | translate}}</span>
            </th>
            <td mat-cell *matCellDef="let element">{{element.PoblacionCUPS}}</td>
             
           </ng-container> -->

           <ng-container matColumnDef="DireccionCUPS" *ngIf="DesarrolloCatgas">
            <th mat-header-cell *matHeaderCellDef>
              <span mat-sort-header> {{'Dirección de suministro' | translate}}</span>
             </th>
             <td mat-cell *matCellDef="let element">{{element.DireccionSuministro}}</td>
            </ng-container>
         

          <!-- Boton Graficos Admin Fincas -->
          <!-- <ng-container matColumnDef="Graficos" *ngIf="MostrarListadoContratoDenominacionCliente && TipoCliente === '3'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Graficos' | translate}} </th>
            <td mat-cell *matCellDef="let element">
              <a mat-raised-button color="primary"
                [routerLink]="[ '/graficos', 'graficos', element.IdContrato+'&'+element.Cups+'&'+element.Identidad ]"
                matTooltip="Ver gráficos" matBadge="{{ element.NumFacturas }}"
                matBadgeColor="warn">
                <span class="fal fa-layer-group fa-lg"></span>
              </a>
            </td>
          </ng-container> -->

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
         
          
        </table>
        <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
          <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
        </div>


        <button *ngIf="MostrarDescargaMasivaFacturasPorCups" matTooltip="Descargar facturas" mat-button color="primary" [disabled]="this.dataSource.data.length < 1"
            class="mt-20 primary" [ngClass]="primary" (click)="descargaMasivaFacturasDialog()">
            <i class="far fa-file-archive"></i> {{'Descarga facturas' | translate}}
        </button>

        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>
  </div>
</div>

<app-footer *ngIf="!monopunto"></app-footer>

<app-perfil-contrato [codigo]="contratos[0].IdContrato" *ngIf="monopunto"></app-perfil-contrato>
