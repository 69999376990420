<!-- <app-header></app-header> -->

<app-header *ngIf="primeraVez"></app-header>
<div id="cambiopass">
<div id="loginWrapper" class="mt-30">
  <div fxLayout="row" fxLayoutAlign="center center" fxFlexFill>
    <div fxFlex="20" [fxFlex.md]="40" [fxFlex.sm]="50" [fxFlex.xs]="100">
      <mat-card>
        <img src="assets/img/logo.svg" id="logoEmpresa" class="logoEmpresapassword" alt="Avatar">
        <mat-card-header fxLayoutAlign="center center">
          <mat-card-title>{{'CAMBIO DE CONTRASEÑA' | translate}}</mat-card-title>
          <mat-card-subtitle>{{'Cambie la contraseña de su cuenta.'| translate}}</mat-card-subtitle>
        </mat-card-header>
        <form #f="ngForm" novalidate (ngSubmit)="cambioContrasena(f)" class="p-20">
          <p *ngIf="!primeraVez" class="primeraVez">
            <span><i class="far fa-info-circle"></i>{{'Al ser la primera vez que se inicia sesión, se debe cambiar la contraseña.' | translate}}</span>
          </p>
        <mat-form-field fxFlexFill class="pb-20" *ngIf="!primeraVez" class="primeraVez">
          <input type="password" matInput name="contrasenaAntigua" [(ngModel)]="contrasenaAntigua" placeholder="{{'Contraseña recibida en su correo' | translate}}" required>
        </mat-form-field>
        <mat-form-field fxFlexFill class="pb-20" *ngIf="primeraVez">
          <input type="password" matInput name="contrasenaAntigua" [(ngModel)]="contrasenaAntigua" placeholder="{{'Introduzca su contraseña actual' | translate}}" required>
        </mat-form-field>
        <mat-form-field fxFlexFill class="pb-20">
          <input type="password" matInput name="contrasenaNueva" [(ngModel)]="contrasenaNueva" placeholder="{{'Escoger NUEVA contraseña' | translate}}" required>
        </mat-form-field>
        <mat-form-field fxFlexFill class="pb-20">
          <input type="password" matInput name="repetirContrasena" [(ngModel)]="repetirContrasena" placeholder="{{'Repetir NUEVA Contraseña' | translate}}" required>
        </mat-form-field>
          <p *ngIf="MostrarAceptacionDeUso" class="primeraVez">
            <input type="checkbox" name="politicaUso" ng-required="MostrarAceptacionDeUso" [(ngModel)]="checkBoxAceptacionDeUso" matTooltip="Marcando esta casilla aceptas la política de uso"/><small>
                {{'Acepto la' | translate}} <a href="{{ urlUso }}" target="_blank" title="política de uso">{{'política de uso'| translate}}
            </a>{{'de la plataforma.'| translate}} </small>
          </p>
          <!-- <mat-form-field fxFlexFill class="pb-20">
            <input type="checkbox" matInput name="politicaUso" [(ngModel)]="politicaUso" required>
            Acepto la política de uso de la plataforma
          </mat-form-field> -->
          <div fxLayout.lt-xl="column" fxLayoutAlign="space-between stretch" fxLayoutGap="20px">
            <button type="submit" mat-raised-button color="primary" [disabled]="f.invalid || (MostrarAceptacionDeUso && !checkBoxAceptacionDeUso)">{{'Cambiar Contraseña'| translate}} </button>
            <a mat-button (click)="back()">{{'Volver'| translate}} </a>
          </div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
</div>
  <app-footer></app-footer>
