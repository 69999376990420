import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MailService } from 'src/app/services/mail/mail.service';
import { Email } from '../../../interfaces/Email';
import { environment } from 'src/environments/environment';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';


@Component({
  selector: 'app-cambios-datos-titular',
  templateUrl: './cambios-datos-titular.component.html',
  styleUrls: ['./cambios-datos-titular.component.scss']
})
export class CambiosDatosTitularComponent implements OnInit {

  // declarations
  entorno: string;

  form: FormGroup;

  IsAdministrador: boolean;
  IdCliente: number;
  Entorno: string;
  
  isLoading = false;


  /* Campos formulario */
  Identidad: string;
  Email: string;
  RazonSocialCliente: string;
  NombreCliente: string;
  Apellido1Cliente: string;
  Apellido2Cliente: string;
  CodigoPostalCliente: string;
  ProvinciaCliente: string;
  CiudadCliente: string;
  DireccionCliente: string;

  IdentificadorRepresentante: any;
  ColectivoRepresentante: any;
  NombreRepresentante: any;

  varOne: number;
  varTwo: number;
  resultSumOneTwo: number;
  captcha: number;


  msgError = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private fb: FormBuilder,
    // private _facturasService: FacturasService,
    // private _funcionesService: FuncionesService,
    private _snackBar: MatSnackBar,
    private _mailService: MailService,
    public dialogRef: MatDialogRef<CambiosDatosTitularComponent>
    ) {
    this.dialogRef.updateSize('100%','100%');

    // this.IsAdministrador = this.data.IsAdministrador;
    // this.IdCliente = this.data.IdCliente;
    // this.Entorno = this.data.Entorno;
  }

  ngOnInit() {
    // console.log(this.data);

    this.Identidad = this.data.IdentificadorClienteDNI;
    this.RazonSocialCliente = this.data.RazonSocialCliente;
    this.NombreCliente = this.data.NombreCliente;
    this.Apellido1Cliente = this.data.Apellido1Cliente;
    this.Apellido2Cliente = this.data.Apellido2Cliente;
    this.CodigoPostalCliente = this.data.CodigoPostalCliente;
    this.ProvinciaCliente = this.data.ProvinciaCliente;
    this.CiudadCliente = this.data.CiudadCliente;
    this.DireccionCliente = this.data.DireccionCliente;

    this.IdentificadorRepresentante = this.data.IdentificadorRepresentante;
    this.ColectivoRepresentante = this.data.ColectivoRepresentante;
    this.NombreRepresentante = this.data.NombreRepresentante;
    
    this.generateNumbers();

  }


  onEnviarCambios() {
    this.isLoading = true;
    let cuerpo = '';
    let title = '';
    let replyTo = '';

    (this.data.EmailContacto !="") ? replyTo = this.data.EmailContacto : null;

    title = '<h3>Los datos solicitados para modificar por el Cliente ' + this.data.IdentificadorClienteDNI + ' son:</h3>';
    cuerpo = (this.data.EmailContacto !="") ? cuerpo + '<p><strong>Email de contacto:</strong> ' + this.data.EmailContacto  + '</p>' : cuerpo;
    cuerpo = (this.data.IdentificadorClienteDNI != this.Identidad) ? cuerpo + '<p>Identificador: ' + this.Identidad + '</p>' : cuerpo;
    cuerpo = (this.data.RazonSocialCliente != this.RazonSocialCliente) ? cuerpo + '<p>Razon Social: ' + this.RazonSocialCliente + '</p>' : cuerpo;
    cuerpo = (this.data.NombreCliente != this.NombreCliente) ? cuerpo + '<p>Nombre: ' + this.NombreCliente + '</p>' : cuerpo;
    cuerpo = (this.data.Apellido1Cliente != this.Apellido1Cliente) ? cuerpo + '<p>Apellido 1: ' + this.Apellido1Cliente + '</p>' : cuerpo;
    cuerpo = (this.data.Apellido2Cliente != this.Apellido2Cliente) ? cuerpo + '<p>Apellido 2: ' + this.Apellido2Cliente + '</p>' : cuerpo;
    cuerpo = (this.data.CodigoPostalCliente != this.CodigoPostalCliente) ? cuerpo + '<p>Codigo postal: ' + this.CodigoPostalCliente + '</p>' : cuerpo;
    cuerpo = (this.data.ProvinciaCliente != this.ProvinciaCliente) ? cuerpo + '<p>Provincia: ' + this.ProvinciaCliente + '</p>' : cuerpo;
    cuerpo = (this.data.CiudadCliente != this.CiudadCliente) ? cuerpo + '<p>Ciudad: ' + this.CiudadCliente + '</p>' : cuerpo;
    cuerpo = (this.data.DireccionCliente != this.DireccionCliente) ? cuerpo + '<p>Direccion: ' + this.DireccionCliente + '</p>' : cuerpo;

    if(this.data.IdentificadorRepresentante!=''){
      cuerpo = (this.data.IdentificadorRepresentante != this.IdentificadorRepresentante) ? cuerpo + '<p>Identificador Representante: ' + this.IdentificadorRepresentante + '</p>' : cuerpo;
      cuerpo = (this.data.ColectivoRepresentante != this.ColectivoRepresentante) ? cuerpo + '<p>Colectivo Representante: ' + this.ColectivoRepresentante + '</p>' : cuerpo;
      cuerpo = (this.data.NombreRepresentante != this.NombreRepresentante) ? cuerpo + '<p>Nombre Representante: ' + this.NombreRepresentante + '</p>' : cuerpo;
    }


    if(cuerpo !=''){

      cuerpo = title + '' + cuerpo;

      let email: Email = {Asunto: 'Envío de formulario de Modifica tus datos', Destinatario: environment.email, Cuerpo: cuerpo, ReplyTo: replyTo};

      this._mailService
        .getEnvioEmail(email)
        .subscribe(async response => {
            this.isLoading = false;
            // console.log(response);

            this.msgError = "Enviado solicitud, lo tramitaremos lo antes posible."
            this._snackBar.open(this.msgError, "Gracias", {
              duration: 2000,
            });

          });

    }else{
      this.isLoading = false;
      this.msgError = "No hay cambios reflejados"
      this._snackBar.open(this.msgError, "Intentelo de nuevo", {
        duration: 2000,
      });

    }

}

// Captcha sencillo
generateNumbers(){
  this.varOne = Math.floor(Math.random() * 10);
  this.varTwo = Math.floor(Math.random() * 10);
  this.resultSumOneTwo = this.varOne + this.varTwo;
}




}
