import { Component, OnInit, ViewChild } from '@angular/core';
import { FuncionesService } from '../../../services/funciones/funciones.service';
import { ContratosService } from '../../../services/contratos/contratos.service';
import { LoginService } from '../../../services/login/login.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FacturasMasivasDialogComponent } from '../../dialogs/facturas-masivas-dialog/facturas-masivas-dialog.component';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginator } from '@angular/material/paginator';
import { Contrato } from '../../../interfaces/contrato';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LoginDialogComponent } from '../../../components/dialogs/login-dialog/login-dialog.component';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.scss']
})
export class ContratosComponent implements OnInit {
  contratos: Contrato[];
  idCliente: any;
  mostrarSpinner = true;
  textoModal: string;
  visibleFacturas: boolean;
  monopunto: boolean;
  isLoading = true;
  tokenvalidado: any;
  tipocliente: any;
  MostrarDescargaMasivaFacturasPorCups: any;
  MostrarAdministradorListadoContratoFechaAlta: any;
  MostrarAdministradorListadoContratoFechaVto: any;
  MostrarAdministradorListadoContratoFechaBaja: any;
  MostrarAdministradorListadoContratoEntorno: any;
  MostrarListadoContratoDireccionSuministro: any;
  MostrarListadoContratoDenominacionCliente: any;
  MostrarListadoContratoBotonGraficos:any;
  IsAdministrador: any;

  dialogRef: any;
  agrupacontratoscups = environment.agrupacontratoscups;
  MostrarDeuda = environment.MostrarDeuda;
  PrefijoNumeracionContratos = environment.PrefijoNumeracionContratos;
  EleiaNombreAdministradorFincas = environment.EleiaNombreAdministradorFincas;
  DesarrolloCatgas = environment.DesarrolloCatgas;

  ApiCallContratosListaElekluz: any;


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [];



  dataSource = new MatTableDataSource<Contrato>();
  selection = new SelectionModel<Contrato>(true, []);

  EntornoFilter = new FormControl();
  NumFincaFilter = new FormControl();
  ContratoFilter = new FormControl();
  SituacionFilter = new FormControl();
  IdentidadFilter = new FormControl();
  NombreFilter = new FormControl();
  DireccionSuministroFilter = new FormControl();
  DenominacionClienteFilter = new FormControl();
  CupsFilter = new FormControl();
  FechaAltaFilter = new FormControl();
  FechaVencimientoFilter = new FormControl();
  FechaBajaFilter = new FormControl();
  TarifaFilter = new FormControl();
  DeudaFilter = new FormControl();

  filteredValues = {
    Entorno: '',
    NumFinca: '',
    Contrato: '',
    Situacion: '',
    Identidad: '',
    Nombre: '',
    DireccionSuministro: '',
    DenominacionCliente: '',
    Cups: '',
    FechaAlta: '',
    FechaVencimiento: '',
    FechaBaja: '',
    Tarifa: '',
    Deuda: ''
  };

  CambiarTitularElekluz: boolean;

  constructor(
    private router: Router,
    private _contratosService: ContratosService,
    private _funcionesService: FuncionesService,
    private _loginService: LoginService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {

    this.ApiCallContratosListaElekluz = environment.ApiCallContratosListaElekluz;

    this.MostrarAdministradorListadoContratoFechaAlta = environment.MostrarAdministradorListadoContratoFechaAlta;
    this.MostrarAdministradorListadoContratoFechaVto = environment.MostrarAdministradorListadoContratoFechaVto;
    this.MostrarAdministradorListadoContratoFechaBaja = environment.MostrarAdministradorListadoContratoFechaBaja;
    this.MostrarAdministradorListadoContratoEntorno = environment.MostrarAdministradorListadoContratoEntorno;
    this.MostrarListadoContratoDireccionSuministro = environment.MostrarListadoContratoDireccionSuministro;
    this.MostrarListadoContratoDenominacionCliente = environment.MostrarListadoContratoDenominacionCliente;
    this.EleiaNombreAdministradorFincas = environment.EleiaNombreAdministradorFincas;


    this.MostrarListadoContratoBotonGraficos = environment.MostrarListadoContratoBotonGraficos;

    this.IsAdministrador = localStorage.getItem('isAdminFincas');

    this.CambiarTitularElekluz = environment.CambiarTitularElekluz;

    this.tipocliente = localStorage.getItem('TipoCliente');

    // this.displayedColumns.push('Entorno');
    (!this.MostrarAdministradorListadoContratoEntorno && this.IsAdministrador) ? null : this.displayedColumns.push('Entorno');
    this.displayedColumns.push('NumFinca');
    if(!this.agrupacontratoscups){
      this.displayedColumns.push('CodigoContrato');
    }
    this.displayedColumns.push('ContratoSituacion');
    this.displayedColumns.push('Identidad');
    this.displayedColumns.push('Nombre');
    // this.displayedColumns.push('DireccionSuministro');
    (!this.MostrarListadoContratoDireccionSuministro) ? null : this.displayedColumns.push('DireccionSuministro');
    (!this.MostrarListadoContratoDenominacionCliente && this.IsAdministrador) ? null : this.displayedColumns.push('DenominacionCliente');
    // this.displayedColumns.push('DenominacionCliente');
    this.displayedColumns.push('Cups');

    (!this.MostrarAdministradorListadoContratoFechaAlta && this.IsAdministrador) ? null : this.displayedColumns.push('FechaAlta');
    (!this.MostrarAdministradorListadoContratoFechaVto && this.IsAdministrador) ? null : this.displayedColumns.push('FechaVto');
    (!this.MostrarAdministradorListadoContratoFechaBaja && this.IsAdministrador) ? null : this.displayedColumns.push('FechaBaja');

    // this.displayedColumns.push('FechaAlta');
    // this.displayedColumns.push('FechaVto');
    // this.displayedColumns.push('FechaBaja');
    this.displayedColumns.push('Tarifa');
    if(this.MostrarDeuda){
      this.displayedColumns.push('Deuda');
    }

    // console.log(this.tipocliente + "" + this.MostrarListadoContratoBotonGraficos);
    (this.MostrarListadoContratoBotonGraficos && this.tipocliente == 3) ? this.displayedColumns.push('Graficos') : null;

    this.displayedColumns.push('Facturas');
    if(this.DesarrolloCatgas){
      // this.displayedColumns.push('Ciudad');
      this.displayedColumns.push('DireccionCUPS');
    }



    this.MostrarDescargaMasivaFacturasPorCups = environment.MostrarDescargaMasivaFacturasPorCups;
    if (localStorage.getItem('isAdminFincas') === 'true') {
      this.visibleFacturas = true;
    } else {
      this.visibleFacturas = false;
      this.displayedColumns = this.displayedColumns.filter(function (col) {
        return col.localeCompare('NumFinca'); // Quitar columna NumFinca
      });
    }

    // console.log(this.displayedColumns);
    // console.log(this.contratos);

    // Settime out puesto porque sin el, peta al recargar la pagina en el listado de contratos
    setTimeout(() => {

            if(this.ApiCallContratosListaElekluz && localStorage.getItem('isAdminFincas') === 'true'){

              // console.log(this.tipocliente);

              this._contratosService
                .getListadoContratosElekluz(localStorage.getItem('idCliente'))
                .subscribe(async data => {
                  this.isLoading = false;
                  this.contratos = this._funcionesService.decodificarToken(data);
                  // console.log(this.contratos);
                  this.monopunto = (this.contratos.length === 1) ? false : false;
                  // console.log(this.contratos);
                  this.dataSource = new MatTableDataSource<Contrato>(this.contratos);
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  this.mostrarSpinner = false;
                  this.customFilter();
                },
                  err => {
                    this.isLoading = false;
                    this.textoModal = this._funcionesService.decodificarToken(
                      err.error
                    )['0'].Error;
                    this.mostrarSpinner = false;
                    this.dialog.open(LoginDialogComponent, {
                      width: '400px',
                      data: {
                        texto: this.textoModal,
                        titulo: 'Error en contratos'
                      }
                    });
                  }
                );

            }else{

              this._contratosService
                .getListadoContratos(localStorage.getItem('idCliente'))
                .subscribe(async data => {
                  this.isLoading = false;
                  this.contratos = this._funcionesService.decodificarToken(data);
                  // console.log(this.contratos);
                  this.monopunto = (this.contratos.length === 1) ? false : false;
                  // console.log(this.contratos);
                  this.dataSource = new MatTableDataSource<Contrato>(this.contratos);
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                  this.mostrarSpinner = false;
                  this.customFilter();
                },
                  err => {
                    this.isLoading = false;
                    this.textoModal = this._funcionesService.decodificarToken(
                      err.error
                    )['0'].Error;
                    this.mostrarSpinner = false;
                    this.dialog.open(LoginDialogComponent, {
                      width: '400px',
                      data: {
                        texto: this.textoModal,
                        titulo: 'Error en contratos'
                      }
                    });
                  }
                );

              };
    }, 4);

////////////////////////////////////////////////////////////////////////////////

  }


  descargaMasivaFacturasDialog() {

    this.dialogRef = this.dialog.open(FacturasMasivasDialogComponent, {
      data:{
        'IsAdministrador': localStorage.getItem('isAdminFincas'),
        'IdCliente': localStorage.getItem('idCliente'),
        'Entorno': localStorage.getItem('entorno'),
        'ListadoContratos': this.dataSource.data
      }
    });


    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
    });

  }


  customFilter() {
    this.EntornoFilter.valueChanges.subscribe(EntornoFilterValue => {
      this.filteredValues['Entorno'] = EntornoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.NumFincaFilter.valueChanges.subscribe(NumFincaFilterValue => {
      this.filteredValues['NumFinca'] = NumFincaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.ContratoFilter.valueChanges.subscribe(ContratoFilterValue => {
      this.filteredValues['Contrato'] = ContratoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.SituacionFilter.valueChanges.subscribe(SituacionFilterValue => {
      this.filteredValues['Situacion'] = SituacionFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.IdentidadFilter.valueChanges.subscribe(IdentidadFilterValue => {
      this.filteredValues['Identidad'] = IdentidadFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.NombreFilter.valueChanges.subscribe(NombreFilterValue => {
      this.filteredValues['Nombre'] = NombreFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.DireccionSuministroFilter.valueChanges.subscribe(DireccionSuministroFilterValue => {
      this.filteredValues['DireccionSuministro'] = DireccionSuministroFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.DenominacionClienteFilter.valueChanges.subscribe(DenominacionClienteFilterValue => {
      this.filteredValues['DenominacionCliente'] = DenominacionClienteFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.CupsFilter.valueChanges.subscribe(CupsFilterValue => {
      this.filteredValues['Cups'] = CupsFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaAltaFilter.valueChanges.subscribe(FechaAltaFilterValue => {
      this.filteredValues['FechaAlta'] = FechaAltaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaVencimientoFilter.valueChanges.subscribe(FechaVencimientoFilterValue => {
      this.filteredValues['FechaVencimiento'] = FechaVencimientoFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.FechaBajaFilter.valueChanges.subscribe(FechaBajaFilterValue => {
      this.filteredValues['FechaBaja'] = FechaBajaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.TarifaFilter.valueChanges.subscribe(TarifaFilterValue => {
      this.filteredValues['Tarifa'] = TarifaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.DeudaFilter.valueChanges.subscribe(DeudaFilterValue => {
      this.filteredValues['Deuda'] = DeudaFilterValue;
      this.dataSource.filter = JSON.stringify(this.filteredValues);
    });
    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  customFilterPredicate() {
    const myFilterPredicate = function (data: Contrato, filter: string): boolean {

      const searchString = JSON.parse(filter);
      if(data.DenominacionCliente == null){
        data.DenominacionCliente = '';
      }
      if(data.FechaAlta == null){
        data.FechaAlta = '';
      }
      if(data.FechaBaja == null){
        data.FechaBaja = '';
      }
      if(data.FechaVto == null){
        data.FechaVto = '';
      }
      if(data.NombreCliente == null){
        data.NombreCliente = '';
      }
      if(data.NumFinca == null){
        data.NumFinca = '';
      }
      if(data.CodigoContrato == null){
        data.CodigoContrato = '';
      }
      if(data.ContratoSituacion == null){
        data.ContratoSituacion = '';
      }
      if(data.Identidad == null){
        data.Identidad = '';
      }
      if(data.NombreCliente == null){
        data.NombreCliente = '';
      }
      if(data.DireccionSuministro == null){
        data.DireccionSuministro = '';
      }
      if(data.Cups == null){
        data.Cups = '';
      }
      if(data.Tarifa == null){
        data.Tarifa = '';
      }
      if(data.PoblacionCUPS == null){
        data.PoblacionCUPS = '';
      }
      if(data.DirecionFact == null){
        data.DirecionFact = '';
      }
      return (
        data.Entorno.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Entorno.toLowerCase()) !== -1 &&
        data.NumFinca.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.NumFinca.toLowerCase()) !== -1 &&
        data.CodigoContrato.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Contrato.toLowerCase()) !== -1 &&
        data.ContratoSituacion.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Situacion.toLowerCase()) !== -1 &&
        data.Identidad.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Identidad.toLowerCase()) !== -1 &&
        data.NombreCliente.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Nombre.toLowerCase()) !== -1 &&
        data.DireccionSuministro.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.DireccionSuministro.toLowerCase()) !== -1 &&
        data.DenominacionCliente.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.DenominacionCliente.toLowerCase()) !== -1 &&
        data.Cups.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Cups.toLowerCase()) !== -1 &&
        data.FechaAlta.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaAlta.toLowerCase()) !== -1 &&
        data.FechaVto.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaVencimiento.toLowerCase()) !== -1 &&
        data.FechaBaja.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.FechaBaja.toLowerCase()) !== -1 &&
        data.Tarifa.toString()
          .trim()
          .toLowerCase()
          .indexOf(searchString.Tarifa.toLowerCase()) !== -1
      );
    };
    return myFilterPredicate;
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
